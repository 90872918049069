import React from 'react';
import { Modal, message, Space } from 'antd';

const SimpleModal = ({
  title = '',
  titleIcon: TitleIcon,
  onOk,
  onSuccess = () => {},
  onSuccessText = null,
  children,
  ...restProps
}) => {
  const handleOnOk = () => {
    const handleOnSuccess = () => {
      onSuccess();
      onSuccessText && message.success(onSuccessText);
    };
    const handleOnError = ({ description } = {}) => {
      message.error(description || 'Error', 5);
    };

    onOk({ onSuccess: handleOnSuccess, onError: handleOnError });
  };

  return (
    <Modal
      title={
        <Space>
          {TitleIcon && (
            <TitleIcon style={{ fontSize: 18, color: '#ed20eb' }} />
          )}
          {title}
        </Space>
      }
      okText="Aceptar"
      cancelText="Cancelar"
      centered={true}
      width={400}
      {...restProps}
      onOk={handleOnOk}
    >
      {children}
    </Modal>
  );
};

export default SimpleModal;
