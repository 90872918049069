import React, { useState } from 'react';
import { useAPI } from 'modules/core/hooks';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Col,
  Typography,
  Spin,
  List,
  Card,
  Button,
  Space,
  message,
} from 'antd';
import { HELPS, PROJECTS } from 'modules/core/constants';
import {
  FloatContainer,
  HelpModal,
  // RoundedTag,
  SimpleModal,
  TitleExtra,
} from 'modules/core/components';

import './StepDiagnostic.scss';

const { Text } = Typography;

const parseDiagnosticData = (data) => {
  return [
    {
      titulo: 'Diagnostico del archivo',
      descripcion: data?.respuesta,
      entidades_faltantes: data?.entidades_faltantes || '',
      resultado:
        data?.status_code === PROJECTS.STATUS_OK
          ? 'Suficiente'
          : 'Insuficiente',
    },
  ];
};

const DiagnosticHeader = ({ startDate, endDate }) => {
  return (
    <div className="new-project-step-diagnostic-header">
      <Text>
        Acá te vamos a mostrar un panorama general de como están tus datos.
      </Text>
      {/* <RoundedTag
        text={`Fecha de inicio: ${startDate} | Fecha Fin: ${endDate}`}
      /> */}
    </div>
  );
};

const DiagnosticList = ({ dataSource = [], loading }) => {
  return (
    <List
      dataSource={dataSource}
      className="new-project-diagnostic-list"
      grid={{
        gutter: 16,
        xs: 1,
        sm: 1,
        md: 1,
        lg: 1,
        xl: 2,
        xxl: 2,
      }}
      locale={{ emptyText: 'No hay diagnóstico disponible' }}
      loading={loading}
      renderItem={({ titulo, descripcion, entidades_faltantes, resultado }) => (
        <List.Item>
          <Card className="new-project-diagnostic-card">
            <span level={5} className="card-title">
              {titulo}
            </span>
            <span>{descripcion}</span>
            <span>{entidades_faltantes}</span>
            <span className="card-result">{resultado}</span>
          </Card>
        </List.Item>
      )}
    />
  );
};

const StepDiagnostic = ({ data, onClickNext, onClickBack }) => {
  const [openPredecirModal, setOpenPredecirModal] = useState(false);
  const [openOnErrorModal, setOpenOnErrorModal] = useState(false);
  const api = useAPI();
  const { data: diagnosticData, isLoading } = useQuery({
    queryKey: [
      'NewProject::fetchFileDiagnostic',
      {
        fileName: data.fileName,
        projectId: data.projectId,
        selectedColumns: data.selectedColumns,
      },
    ],
    queryFn: api.fetchFileDiagnostic,
  });

  const { mutate: runProjectPrediction, isLoading: isRunningPrediction } =
    useMutation({
      mutationFn: api.runProjectPrediction,
    });

  const handleOnOk = () => {
    runProjectPrediction(data.projectId, {
      onSuccess: (r) => {
        if (r['Job ID']) {
          message.success(
            'Comenzo la predicción de tu proyecto. Te notificaremos cuando termine.'
          );
          setOpenPredecirModal(false);
          setOpenOnErrorModal(false);
          onClickNext();
        } else {
          setOpenOnErrorModal(true);
        }
      },
      onError: (r) => {
        setOpenOnErrorModal(true);
      },
    });
  };

  return (
    <>
      <Col span={24}>
        <TitleExtra title="Diagnóstico" level={4} />
      </Col>
      {isLoading && <Spin />}
      {!isLoading && (
        <>
          <Col span={24}>
            <DiagnosticHeader
              startDate={data?.fechaInicio}
              endDate={data?.fechaFin}
            />
          </Col>
          <Col span={24}>
            <DiagnosticList
              loading={isLoading}
              dataSource={parseDiagnosticData(diagnosticData)}
            />
          </Col>
          <Col span={24}>
            <FloatContainer doubleInset>
              <Space>
                <Button
                  size="large"
                  style={{ minWidth: 200 }}
                  onClick={() => onClickBack()}
                  loading={isRunningPrediction}
                >
                  Cargar nuevamente los datos
                </Button>
                <Button
                  type="primary"
                  size="large"
                  style={{ minWidth: 200 }}
                  onClick={() => setOpenPredecirModal(true)}
                  loading={isRunningPrediction}
                  disabled={diagnosticData?.status_code !== PROJECTS.STATUS_OK}
                >
                  Predecir
                </Button>
              </Space>
            </FloatContainer>
            <HelpModal screen={HELPS.SCREENS.DIAGNOSTIC_STEP} />
          </Col>
          <SimpleModal
            title="Ejecutar predicción"
            open={openPredecirModal}
            okText="Predecir"
            onOk={handleOnOk}
            confirmLoading={isRunningPrediction}
            onCancel={() => setOpenPredecirModal(false)}
          >
            <Text>¿Deseas ejecutar la predicción?</Text>
          </SimpleModal>
          <SimpleModal
            title="Fallo la ejecución de la predicción"
            open={openOnErrorModal}
            okText="Volver a ejecutar"
            onOk={handleOnOk}
            confirmLoading={isRunningPrediction}
            onCancel={() => setOpenOnErrorModal(false)}
          >
            <Text>Por favor, vuelve ejecuta nuevamente la predicción</Text>
          </SimpleModal>
        </>
      )}
    </>
  );
};

export default StepDiagnostic;
