import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { Upload, message, Button } from 'antd';

const validType = (types, type) => {
  const isValid = types.some((t) => t.type === type);
  if (!isValid)
    message.error(
      `Sólo puede subir archivos con formato ${types
        .map((t) => t.label)
        .join(', ')}`
    );
  return isValid;
};
const validSize = (max, size) => {
  const isValid = size / 1024 / 1024 < max;
  if (!isValid) message.error(`El archivo tiene que ser menor a ${max}MB`);
  return isValid;
};

const UploadButton = ({
  apiFn,
  onUpload,
  uploadName = 'file',
  fileTypes = [{ type: 'text/plain', label: 'TEXT' }],
  maxSize = 3,
  children,
  loading,
  ...props
}) => {
  const { mutate: uploadFile, isLoading } = useMutation({ mutationFn: apiFn });

  const beforeUpload = ({ type, size }) =>
    validType(fileTypes, type) && validSize(maxSize, size);

  const onUploadFile = ({ file }) => {
    const handleOnSuccess = () => {
      onUpload && onUpload();
      message.success('Archivo cargado correctamente');
    };
    const handleOnError = () => {
      message.error('No se pudo cargar el archivo');
    };
    const fileAsForm = new FormData();
    fileAsForm.append('file', file);
    return uploadFile(
      { inputFile: fileAsForm },
      { onSuccess: handleOnSuccess, onError: handleOnError }
    );
  };

  return (
    <Upload
      name={uploadName}
      showUploadList={false}
      accept={fileTypes.map(({ type }) => type)}
      beforeUpload={beforeUpload}
      customRequest={onUploadFile}
    >
      <Button loading={loading || isLoading} {...props}>
        {children}
      </Button>
    </Upload>
  );
};

export default UploadButton;
