import React from 'react';
import { useAPI } from 'modules/core/hooks';
import { useQuery } from '@tanstack/react-query';
import { Row, Col } from 'antd';
import { Table, TitleExtra } from 'modules/core/components';
// import { DATETIME } from 'modules/core/utils';

const columns = [
  {
    title: 'Notificación',
    dataIndex: 'message',
    key: 'message',
  },
  {
    title: 'Fecha',
    dataIndex: 'fecha_creacion',
    key: 'fecha_creacion',
    width: '25%',
    // render: (d) => DATETIME.formatDateTime(d),
  },
];

const Notifications = () => {
  const api = useAPI();
  const { isSuccess } = useQuery({
    queryKey: ['User::viewAllUserNotifications'],
    queryFn: api.viewAllUserNotifications,
  });

  const { data: notifications, isLoading } = useQuery({
    queryKey: ['User::fetchNotifications'],
    queryFn: api.fetchUserNotifications,
    enabled: isSuccess,
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <TitleExtra title="Tus notificaciones" />
      </Col>
      <Col span={12}>
        <Table
          rowKey="message_id"
          columns={columns}
          dataSource={notifications}
          loading={isLoading}
        />
      </Col>
    </Row>
  );
};

export default Notifications;
