import React from 'react';
import { useParams } from 'react-router-dom';
import { useAPI } from 'modules/core/hooks';
import { useQuery } from '@tanstack/react-query';
import { RightOutlined } from '@ant-design/icons';
import { Row, Col, Space, Tabs, Typography, Spin } from 'antd';
import { RoundedTag, TitleExtra } from 'modules/core/components';
import Insights from './Insights/Insights';
import DashboardResults from './DashboardResults/DashboardResults';

const { Text } = Typography;

const ProjectResults = ({ isDemo = false }) => {
  const api = useAPI();
  let { projectId } = useParams();
  const { data: projectDetail, isLoading } = useQuery({
    queryKey: ['Project::fetchProjectDetail', { projectId, isDemo }],
    queryFn: api.fetchProjectDetail,
  });

  const renderProjectHeader = () => (
    <>
      <Col span={24}>
        <Text type="secondary">
          <Space>
            <RightOutlined />
            {projectDetail?.objective?.description}
          </Space>
        </Text>
      </Col>
      <Col span={24}>
        <TitleExtra
          topAligned
          title={projectDetail?.project_name}
          extra={
            <Text style={{ fontSize: 12 }} type="secondary">
              {projectDetail?.project_desc}
            </Text>
          }
        />
      </Col>
    </>
  );

  return (
    <Row gutter={[16, 16]}>
      {isLoading && <Spin spinning={isLoading} />}
      {!isLoading && (
        <>
          {renderProjectHeader()}
          <Col span={24}>
            <Tabs
              defaultActiveKey="resultados"
              items={[
                {
                  key: 'resultados',
                  label: 'Resultados',
                  children: <DashboardResults isDemo={isDemo} />,
                },
                {
                  key: 'insights',
                  label: 'Accionables',
                  children: <Insights isDemo={isDemo} />,
                },
              ]}
              tabBarExtraContent={
                <RoundedTag
                  text={`Objetivo: ${projectDetail?.objective?.description} | Tipo de Proyecto: ${projectDetail?.project_type?.description}`}
                />
              }
            />
          </Col>
        </>
      )}
    </Row>
  );
};

export default ProjectResults;
