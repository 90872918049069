import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import SiderMenu from './SiderMenu/SiderMenu';
import AppHeader from './AppHeader/AppHeader';
import logoBlack from 'assets/logo-pready-black.svg';

import './AppLayout.scss';

const { Content, Sider } = Layout;

const AppLayout = () => {
  return (
    <Layout className="app-layout">
      <Sider
        className="app-layout-sider"
        theme="light"
        width={223}
        breakpoint="md"
        collapsed={false}
      >
        <img
          src={logoBlack}
          alt="Logo Pready"
          className="app-layout-sider-logo"
        />
        <SiderMenu />
      </Sider>
      <Layout className="app-layout-container">
        <AppHeader />
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
