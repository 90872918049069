import React from 'react';
import { ConfigProvider, Button } from 'antd';

const ButtonSecondary = ({ children, ...restProps }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: '#000000',
            colorPrimaryActive: '#000000',
            colorPrimaryBorder: '#262626',
            colorPrimaryHover: '#0d0d0d',
            controlOutline: 'rgba(145, 145, 255, 0.1)',
          },
        },
      }}
    >
      <Button {...restProps}>{children}</Button>
    </ConfigProvider>
  );
};

export default ButtonSecondary;
