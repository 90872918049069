import React from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { Badge, Dropdown, Tag, Tooltip } from 'antd';
import { OBJECTIVES } from 'modules/core/constants';

const StatusTag = ({ status = OBJECTIVES.STATUS.INACTIVO }) => {
  return (
    <Tag color={OBJECTIVES.STATUS_FORMATTED[status].backgroundColor}>
      <Badge
        text={OBJECTIVES.STATUS_FORMATTED[status].title}
        color={OBJECTIVES.STATUS_FORMATTED[status].color}
        style={{ color: OBJECTIVES.STATUS_FORMATTED[status].color }}
      />
    </Tag>
  );
};

const StatusDropdown = ({
  status = OBJECTIVES.STATUS.INACTIVO,
  disabled = false,
  onClick = () => {},
}) => {
  const items = [
    {
      key: OBJECTIVES.STATUS.INACTIVO,
      label: (
        <>
          <StatusTag status={OBJECTIVES.STATUS.INACTIVO} />
          {status === OBJECTIVES.STATUS.INACTIVO && <CheckOutlined />}
        </>
      ),
      onClick: () => onClick(OBJECTIVES.STATUS.INACTIVO),
    },
    {
      type: 'divider',
    },
    {
      key: OBJECTIVES.STATUS.ACTIVO,
      label: (
        <>
          <StatusTag status={OBJECTIVES.STATUS.ACTIVO} />
          {status === OBJECTIVES.STATUS.ACTIVO && <CheckOutlined />}
        </>
      ),
      onClick: () => onClick(OBJECTIVES.STATUS.ACTIVO),
    },
  ];

  if (!OBJECTIVES.STATUS_FORMATTED[status]) {
    return '-';
  }

  return (
    <Tooltip
      title={
        status === OBJECTIVES.STATUS.COMPLETO
          ? 'Acción finalizada'
          : 'Ejecutar acción'
      }
    >
      <Dropdown
        trigger={['click']}
        menu={{ items }}
        disabled={status === OBJECTIVES.STATUS.COMPLETO || disabled}
      >
        <a href="/#" onClick={(e) => e.preventDefault()}>
          <StatusTag status={status} />
        </a>
      </Dropdown>
    </Tooltip>
  );
};
export default StatusDropdown;
