const pready = {
  token: {
    // colorPrimary: '#000000',
    colorPrimary: '#ed20eb',
    fontFamily:
      "-apple-system, BlinkMacSystemFont, Poppins, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
  },
  components: {
    Button: {
      borderRadius: 16,
    },
    Steps: {
      colorPrimary: '#000000',
      controlItemBgActive: '#d9d9d9',
    },
  },
};

const theme = { pready: pready };

export default theme;
