import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { DownloadOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Button, Col, Collapse, Row, Space, message, Spin } from 'antd';
import { useAPI } from 'modules/core/hooks';
import { HELPS } from 'modules/core/constants';
import { HelpModal } from 'modules/core/components';
import { ActionsPanel } from './components';

import './Insights.scss';

const { Panel } = Collapse;

const Insights = ({ isDemo = false }) => {
  const api = useAPI();
  let { projectId } = useParams();
  const [expandedPanels, setExpandedPanels] = useState([]);

  const [createAccionableModal, setCreateAccionableModal] = useState(false);

  const {
    data: insights,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['Project::fetchProjectInsights', { projectId, isDemo }],
    queryFn: api.fetchProjectInsights,
    select: (d) => d.insights || [],
  });

  const { mutate: fetchProjectInsightsFile, isLoading: isFetchingFile } =
    useMutation({
      mutationFn: api.fetchProjectInsightsFile,
    });
  const handleOnDownloadFile = (file) =>
    fetchProjectInsightsFile(
      { projectId, file },
      {
        onSuccess: (r) => window.open(r?.url),
        onError: () => message.error('No se ha podido acceder al archivo.'),
      }
    );

  const isPanelExpanded = (panelId) => expandedPanels.includes(panelId);

  const handleOnExpandPanel = (panelId) => {
    setExpandedPanels((prevExpandedPanels) => {
      if (isPanelExpanded(panelId)) {
        return prevExpandedPanels.filter((p) => p !== panelId);
      }
      return [...prevExpandedPanels, panelId];
    });
  };

  const renderPanelHeader = ({ id, message, subtitle, actions }) => (
    <Space direction="vertical">
      <span className="panel-header-title">{message}</span>
      <span className="panel-header-subtitle">{subtitle}</span>
      {!isPanelExpanded(id) && (
        <span className="panel-header-quantity">
          {`${actions || 0} accionables`}
        </span>
      )}
    </Space>
  );

  const renderPanelExtra = ({ insight_id, customers_path }) =>
    !isDemo &&
    isPanelExpanded(insight_id) && (
      <Space>
        <Button
          icon={<DownloadOutlined />}
          loading={isFetchingFile}
          onClick={(event) => {
            handleOnDownloadFile(customers_path);
            event.stopPropagation();
          }}
        >
          Descargar datos
        </Button>
        <Button
          type="primary"
          icon={<PlusCircleFilled />}
          onClick={(event) => {
            setCreateAccionableModal(true);
            event.stopPropagation();
          }}
        >
          Crear accionable
        </Button>
      </Space>
    );

  if (isLoading) return <Spin />;

  return (
    <Row gutter={[16, 16]} className="project-results-insights">
      {insights?.map((el) => (
        <Col key={el.insight_id} span={24}>
          <Collapse
            bordered={false}
            className="accionable-collapse"
            onChange={(e) => handleOnExpandPanel(el.insight_id)}
          >
            <Panel
              key={el.insight_id}
              header={renderPanelHeader(el)}
              extra={renderPanelExtra(el)}
            >
              {expandedPanels.includes(el.insight_id) && (
                <ActionsPanel
                  projectId={projectId}
                  isDemo={isDemo}
                  insightId={el.insight_id}
                  openCreateModal={createAccionableModal}
                  onCreateModalOk={() => setCreateAccionableModal(false)}
                  onRefetchInsights={refetch}
                />
              )}
            </Panel>
          </Collapse>
        </Col>
      ))}
      <HelpModal screen={HELPS.SCREENS.ACCIONABLES} />
    </Row>
  );
};

export default Insights;
