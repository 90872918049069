import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Row, Col, Spin } from 'antd';
import _ from 'lodash';
import { useAPI } from 'modules/core/hooks';
import { PROJECTS } from 'modules/core/constants';
import NewProject from '../NewProject/NewProject';
import ProjectStatus from '../ProjectResults/ProjectStatus';
import ProjectResults from '../ProjectResults/ProjectResults';

const ProjectStatusValidator = () => {
  const api = useAPI();
  let { projectId } = useParams();
  const { data: project, isLoading: loadingProjects } = useQuery({
    queryKey: ['Projects::fetchProjectDetail', { projectId }],
    queryFn: api.fetchProjectDetail,
    cacheTime: 0,
  });

  if (loadingProjects) return <LoadingScreen />;

  if (!_.isEmpty(project)) {
    switch (project.status) {
      case PROJECTS.STATUS.CREADO:
        return (
          <NewProject
            step={1}
            initialData={{
              projectId,
              fechaInicio: project.fecha_inicio,
              fechaFin: project.fecha_fin,
            }}
          />
        );
      case PROJECTS.STATUS.CORRIENDO:
        return <ProjectStatus.Running project={project} />;
      case PROJECTS.STATUS.COMPLETO:
        return <ProjectResults />;
      case PROJECTS.STATUS.FALLO:
        return <ProjectStatus.Error project={project} />;

      default:
        return <ProjectStatus.Error project={project} hasInvalidState />;
    }
  } else {
    return <ProjectStatus.ErrorNotFound />;
  }
};

const LoadingScreen = () => (
  <Row gutter={[16, 16]}>
    <Col span={12}>
      <Spin />
    </Col>
  </Row>
);

export default ProjectStatusValidator;
