const HOME = 'home';
const NEW_PROJECT = 'newProject';
const ADD_DATA_STEP = 'addDataStep';
const CONFIGURATION_STEP = 'configurationStep';
const DIAGNOSTIC_STEP = 'diagnosticStep';
const RESULTS = 'results';
const ACCIONABLES = 'actionables';

const SCREENS = {
  HOME,
  NEW_PROJECT,
  ADD_DATA_STEP,
  CONFIGURATION_STEP,
  DIAGNOSTIC_STEP,
  RESULTS,
  ACCIONABLES,
};

const TYPES = {
  TEXT: 'text',
  IMAGE: 'image',
  VIDEO: 'video',
};

const HELPS = {
  SCREENS,
  TYPES,
};

export default HELPS;
