import React, { useEffect } from 'react';
import { Col, Form, Input, Row } from 'antd';
// import { useResetFormOnCloseModal } from 'modules/core/hooks';
import SimpleModal from '../SimpleModal/SimpleModal';

// TODO: Connect with backend.
const ProjectNameModal = ({
  open,
  initialValues = {},
  extraValues = {},
  isEditing = false,
  confirmLoading,
  onCreate = () => {},
  onEdit = () => {},
  onCancel,
}) => {
  const [form] = Form.useForm();

  // useResetFormOnCloseModal({ form, open });
  useEffect(() => {
    if (open) form.setFieldsValue(initialValues);
  }, [form, initialValues, open]);

  // const handleOnOk = ({ onSuccess, onError }) => {
  const handleOnOk = (responseHandlers) => {
    form
      .validateFields()
      .then((values) => {
        const okFn = isEditing ? onEdit : onCreate;
        okFn({ ...values, ...extraValues }, responseHandlers);
      })
      .catch(() => {});
  };

  return (
    <SimpleModal
      title={`${isEditing ? 'Editar' : 'Crear'} proyecto`}
      open={open}
      okText={isEditing ? 'Editar' : 'Crear'}
      onOk={handleOnOk}
      okButtonPros={{ disabled: confirmLoading }}
      confirmLoading={confirmLoading}
      onSuccessText={`El proyecto fue ${
        isEditing ? 'editado' : 'creado'
      } con éxito`}
      onCancel={onCancel}
      width={650}
    >
      <Form form={form} layout="vertical" requiredMark={false}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Nombre"
              name="project_name"
              rules={[{ required: true, message: 'Este campo es requerido' }]}
            >
              <Input placeholder="Nombre Proyecto" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Descripción"
              name="project_desc"
              rules={[{ required: true, message: 'Este campo es requerido' }]}
            >
              <Input.TextArea
                showCount
                autoSize={{ minRows: 3, maxRows: 6 }}
                rules={[{ required: true, message: 'Este campo es requerido' }]}
                maxLength={280}
                placeholder="Descripción de qué se trata tu proyecto. Máx 280 caracteres."
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </SimpleModal>
  );
};

export default ProjectNameModal;
