import React from 'react';
import { List } from 'antd';
import ProjectCard from '../ProjectCard/ProjectCard';

// TODO: Connect edit when available
const ProjectGrid = ({
  data,
  loading,
  onNew,
  // onEdit,
  onDelete,
  onClick,
  emptyText = 'Sin Proyectos',
  selectedItems = [],
}) => {
  const projects = onNew ? [{ new: true }].concat(data ?? []) : data;
  const isCardDimmed = (itemId) => {
    if (selectedItems.length > 0) {
      return !selectedItems.includes(itemId);
    }
    return false;
  };

  return (
    <List
      grid={{ gutter: [36, 20] }}
      dataSource={projects}
      locale={{ emptyText }}
      loading={loading}
      renderItem={(p) => (
        <List.Item>
          <ProjectCard
            newProject={p.new}
            title={p.project_name}
            description={p.project_desc}
            tooltip={p.tooltip}
            projectType={p.project_type_id}
            color={p.objective?.color}
            isDimmed={isCardDimmed(p.project_id)}
            onClick={p.new ? onNew : () => onClick(p)}
            // onEdit={onEdit && (() => onEdit(p))}
            onDelete={onDelete && (() => onDelete(p))}
          />
        </List.Item>
      )}
    />
  );
};

export default ProjectGrid;
