import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { PATHS } from 'modules/core/constants';
import { useAPI } from 'modules/core/hooks';
import { LOCAL_STORAGE } from 'modules/core/utils';
import { MessageScreen } from 'modules/core/components';

const Logout = () => {
  const api = useAPI();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate: logout } = useMutation({ mutationFn: api.logout });

  useEffect(() => {
    const onSuccess = () => {
      api.withAuthUser(null);
      LOCAL_STORAGE.TOKEN.remove();
      queryClient.clear();
      navigate(PATHS.LOGIN);
    };
    const onError = () => {
      message.error('Error cerrando sesión', 5);
      navigate(PATHS.HOME);
    };
    logout({}, { onSuccess, onError });
  }, [api, queryClient, logout, navigate]);

  return <MessageScreen body="Cerrando sesión..." />;
};

export default Logout;
