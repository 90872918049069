import React from 'react';
import { Steps } from 'antd';
import StepData from './Steps/StepData';
import StepRole from './Steps/StepRole';
import StepTools from './Steps/StepTools';
import StepNeeds from './Steps/StepNeeds';
import StepFinish from './Steps/StepFinish';

const STEPS = {
  0: StepData,
  1: StepRole,
  2: StepTools,
  3: StepNeeds,
  4: StepFinish,
};

const steps = [
  { title: 'Datos' },
  { title: 'Perfil' },
  { title: 'Herramientas' },
  { title: 'Intereses' },
];

const RegisterStepper = ({ currentStep, onNext, onSkip }) => {
  const CurrentStep = STEPS[currentStep];
  if (!CurrentStep) return null;
  return (
    <>
      {currentStep !== 4 && (
        <Steps
          current={currentStep}
          size="small"
          labelPlacement="vertical"
          items={steps}
        />
      )}
      <div className="pready-register-stepper">
        <CurrentStep onNext={onNext} onSkip={onSkip} />
      </div>
    </>
  );
};

export default RegisterStepper;
