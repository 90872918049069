import React from 'react';
import BADGES from './badges';
import '../ProjectCard.scss';

const ProjectCardCreate = ({ onClick }) => {
  return (
    <div
      className={'project-card-create'}
      style={{ backgroundColor: BADGES.NEW.backgroundColor }}
      onClick={onClick}
    >
      <img
        className={'project-card-create-badge'}
        src={BADGES.NEW.icon}
        alt="badge icon"
      />
      <span
        style={{ color: BADGES.NEW.color }}
        className={'project-card-create-description'}
      >
        Nuevo Proyecto
      </span>
    </div>
  );
};

export default ProjectCardCreate;
