import _ from 'lodash';
import {
  PROJECT_KEYMAP,
  PROJECT_TYPE_KEYMAP,
  ACTION_WHERE_KEYMAP,
  ACTION_WHAT_KEYMAP,
} from './apiKeyMaps';

const propertyParser =
  (keyMap, reverse = false) =>
  (data) =>
    _.mapKeys(
      data,
      (_, k) => (reverse ? _.findKey(keyMap, k) : keyMap[k]) ?? k
    );

const baseParser =
  (keyMap) =>
  (data, { reverse = false } = {}) => {
    const parser = propertyParser(keyMap, reverse);
    return Array.isArray(data) ? _.map(data, parser) : parser(data);
  };

const API_PARSER = {
  parseProject: baseParser(PROJECT_KEYMAP),
  parseProjectType: baseParser(PROJECT_TYPE_KEYMAP),
  parseWhereOptions: baseParser(ACTION_WHERE_KEYMAP),
  parseWhatOptions: baseParser(ACTION_WHAT_KEYMAP),
};

export default API_PARSER;
