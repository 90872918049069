import { LOCAL_STORAGE } from 'modules/core/utils';
// revisa antes de buildiar
const PROD_URL = 'https://api.pready.ai/api/v1';
//const PROD_URL = 'http://desa.pready.ai:8000/api/v1';

const getApiUrl = () =>
  process.env.REACT_APP_API_URL
    ? `${process.env.REACT_APP_API_URL}/api/v1`
    : 'http://desa.pready.ai:8000/api/v1';
const API_URL = process.env.NODE_ENV === 'development' ? getApiUrl() : PROD_URL;

// NOTE: Include queryString helpers if needed
const APIPrototype = {
  withAuthUser(authToken) {
    APIPrototype.authToken = authToken;
    return this;
  },

  getJSONHeaders() {
    return { 'Content-Type': 'application/json' };
  },

  getAuthHeaders() {
    return { Authorization: `Bearer ${this.authToken}` };
  },

  getAuthJSONHeaders() {
    return {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${APIPrototype.authToken}`,
    };
  },

  handleResponse(response) {
    const handle = (r) => (r.status === 204 ? r : Promise.resolve(r.json()));
    const reject = (r) =>
      r.text().then((t) => {
        console.debug('Status', r.status, 'Description', t);
        throw Object.assign(new Error(r.status), { description: t });
      });
    const handleExpired = (r) => {
      if (r.status === 401) {
        APIPrototype.withAuthUser(null);
        LOCAL_STORAGE.TOKEN.remove();
        window.location.reload();
      }
      return r;
    };
    return response?.ok ? handle(response) : reject(handleExpired(response));
  },

  handleBlob(response) {
    const handle = (r) => r.blob();
    const reject = (r) =>
      r.text().then((t) => {
        console.debug('Status', r.status, 'Description', t);
        throw new Error(r.status);
      });
    return response?.ok ? handle(response) : reject(response);
  },

  fetchAPIAndHandle(url, opts) {
    return fetch(`${API_URL}${url}`, opts).then(APIPrototype.handleResponse);
  },
};

export default APIPrototype;
