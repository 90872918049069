import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Space, Typography } from 'antd';
import { LoginTitle } from 'modules/login/components';
import { PATHS } from 'modules/core/constants';

const StepFinish = () => {
  const navigate = useNavigate();
  return (
    <Space direction="vertical" size="large">
      <LoginTitle
        title="La cuenta fue creada"
        subtitle={
          <Typography.Text>
            Tu usuario ha sido creado con éxito para que empieces a disfrutar de
            los análisis predictivos que tiene para ofrecerte{' '}
            <Typography.Text strong>PREADY</Typography.Text>.
          </Typography.Text>
        }
      />
      <Button
        type="primary"
        size="large"
        style={{ minWidth: 200, marginTop: 15 }}
        onClick={() => navigate(PATHS.LOGIN)}
      >
        Volver al login
      </Button>
    </Space>
  );
};

export default StepFinish;
