import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAPI } from 'modules/core/hooks';
import { useQuery } from '@tanstack/react-query';
import { Alert, Button, Col, Select, Space, Spin, Typography } from 'antd';
import { HELPS, PROJECTS } from 'modules/core/constants';
import {
  FloatContainer,
  HelpModal,
  Table,
  TitleExtra,
} from 'modules/core/components';

const { Text } = Typography;

// UTILS
const parseDataTypes = (types) =>
  types.map((t) => ({
    value: t.entityName,
    label: t.entityName,
    description: t.entityEsDesc,
  }));

const parseFileInfoData = (data) => {
  if (data.status_code !== PROJECTS.STATUS_OK) return { error: data.respuesta };
  /* fileInfo is correct */
  const parsedColumns = Object.entries(data.respuesta).map(
    ([columna, tipo]) => ({ columna, tipo: tipo[0] })
  );
  return {
    data: parsedColumns,
    columnSize: parsedColumns.length,
  };
};

const disableNext = (selectedRows, changedColumnsTypes) => {
  return (
    !(selectedRows.keys.length > 0) ||
    selectedRows.rows.some(
      (r) =>
        (changedColumnsTypes[r.columna] ?? r.tipo) === PROJECTS.DATA_TYPE_NA
    )
  );
};

const genNextData = (selectedRows, changedColumnsTypes) => {
  const nextDataArray = selectedRows.rows.map((r) => ({
    [r.columna]: [changedColumnsTypes[r.columna] ?? r.tipo],
  }));
  return Object.assign({}, ...nextDataArray);
};
// \\UTILS

const FileInfoText = ({
  loading = true,
  name = '-',
  rowSize,
  columnSize,
  error,
  onClickAction,
}) => {
  if (loading) return <Spin />;
  return (
    <Space direction="vertical" size="small" style={{ width: '100%' }}>
      <Text>{`Nombre archivo: ${name}`}</Text>
      <Space size="small">
        {rowSize && <Text>{`${rowSize} filas`}</Text>}
        {columnSize && <Text>{`${columnSize} columnas`}</Text>}
      </Space>
      {error && (
        <Alert
          message="Error"
          description={error}
          type="error"
          showIcon
          action={
            <Link onClick={() => onClickAction()}>Elegir otro archivo</Link>
          }
        />
      )}
    </Space>
  );
};

//Text strong>Elegimos las columnas más relevantes para el análisis.</Text
const TableHelper = () => (
  <Space direction="vertical" size="small">
    <Text>
      La información mínima necesaria para ejecutar la predicción requiere estos
      tipos de datos entre las columnas:
    </Text>
    <Text>
      - fechaTransaccion o idTransaccion - idCliente -
      cantidadProductoTransaccion - precioUnidad - montoTotalTransaccion (si no
      tenes las columnas cantidadProductoTransaccion y precioUnidad)
    </Text>
    <Text>
      Estas columnas pueden llamarse de manera diferente en tus datos, el
      selector te permite indicar a qué tipo de dato se corresponde cada
      columna.
    </Text>
    <Text Strong>
      Importante: Es posible subir columnas adicionales y no utilizarlas al
      configurarlas como “entidadNoDefinida” Por favor valida que los tipos de
      datos en cada columna son correctos. Esto va permitir realizar análisis
      más efectivos.
    </Text>
  </Space>
);

const StepConfiguration = ({ data, onClickNext, onClickBack }) => {
  const api = useAPI();
  const [selectedRows, setSelectedRows] = useState({ keys: [], rows: [] });
  const [changedColumnsTypes, setChangedColumnsTypes] = useState({});

  const { data: dataTypesOptions, isLoading: isLoadingDataTypes } = useQuery({
    queryKey: ['NewProject::fetchDataTypes'],
    queryFn: api.fetchDataTypes,
    select: parseDataTypes,
  });

  const { data: fileInfo, isLoading } = useQuery({
    queryKey: [
      'NewProject::fetchFileInfo',
      { fileName: data.fileName, projectId: data.projectId },
    ],
    queryFn: api.fetchFileInfo,
    select: parseFileInfoData,
  });

  const rowSelection = {
    selectedRowKeys: selectedRows.keys,
    onChange: (keys, rows) => setSelectedRows({ keys, rows }),
  };

  const handleOnNext = () => {
    onClickNext({
      ...data,
      fileName: data.fileName,
      projectId: data.projectId,
      selectedColumns: genNextData(selectedRows, changedColumnsTypes),
    });
  };

  const columns = [
    {
      title: 'Columna',
      dataIndex: 'columna',
      key: 'columna',
    },
    {
      title: 'Tipo de dato',
      dataIndex: 'tipo',
      key: 'tipo',
      render: (_, record) => (
        <Select
          style={{ width: '100%' }}
          loading={isLoadingDataTypes}
          defaultValue={record.tipo}
          options={dataTypesOptions}
          onChange={(v) =>
            setChangedColumnsTypes((prevChangedColumnsTypes) => ({
              ...prevChangedColumnsTypes,
              [record.columna]: v,
            }))
          }
        />
      ),
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      render: (_, record) => {
        const currentType = changedColumnsTypes[record.columna] ?? record.tipo;
        return dataTypesOptions?.find(({ label }) => label === currentType)
          ?.description;
      },
    },
  ];

  return (
    <>
      <Col span={24}>
        <TitleExtra title="Configuración" level={4} />
      </Col>
      <Col span={24}>
        <FileInfoText
          name={data.fileName}
          onClickAction={onClickBack}
          loading={isLoading}
          rowSize={fileInfo?.rowSize}
          columnSize={fileInfo?.columnSize}
          error={fileInfo?.error}
        />
      </Col>
      {fileInfo?.data && (
        <Col span={24}>
          <TableHelper />
          <Table
            rowKey="columna"
            dataSource={fileInfo?.data}
            columns={columns}
            rowSelection={rowSelection}
            scroll={{ y: 800 }}
          />
        </Col>
      )}
      <Col span={24}>
        <FloatContainer doubleInset>
          <Button
            type="primary"
            size="large"
            style={{ minWidth: 200 }}
            onClick={handleOnNext}
            disabled={disableNext(selectedRows, changedColumnsTypes)}
          >
            Siguiente
          </Button>
        </FloatContainer>
        <HelpModal screen={HELPS.SCREENS.CONFIGURATION_STEP} />
      </Col>
    </>
  );
};

export default StepConfiguration;
