const CREADO = 'Created';
const CORRIENDO = 'Running';
const COMPLETO = 'Completed';
const FALLO = 'Failed';

const STATUS = { CREADO, CORRIENDO, COMPLETO, FALLO };
const STATUS_LIST = Object.keys(STATUS);

const STATUS_OK = '105 Correct';

const DATA_TYPE_NA = 'entidadNoIdentificada';

const STEPS_ID = {
  CREAR: 0,
  AGREGAR_DATOS: 1,
  CONFIGURACION: 2,
  DIAGNOSTICO: 3,
};

const STEPS = {
  [STEPS_ID.CREAR]: { title: 'Crear proyecto' },
  [STEPS_ID.AGREGAR_DATOS]: { title: 'Agregar datos' },
  [STEPS_ID.CONFIGURACION]: { title: 'Configuración' },
  [STEPS_ID.DIAGNOSTICO]: { title: 'Diagnóstico' },
};

const STEPS_LIST = Object.values(STEPS);

const PROJECTS = {
  STATUS,
  STATUS_LIST,
  STATUS_OK,
  DATA_TYPE_NA,
  STEPS,
  STEPS_LIST,
  STEPS_ID,
};

export default PROJECTS;
