import React from 'react';
import { Space } from 'antd';

import './UserPlanPill.scss';

const UserPlanPill = ({ tier = '', numberOfUsers = 0 }) => {
  return (
    <div className="user-plan-pill">
      <Space direction="vertical">
        <div>Tu plan</div>
        <div className="user-plan-tier">{tier}</div>
      </Space>
      {numberOfUsers > 0 && (
        <Space direction="vertical">
          <div>Cantidad de usuarios</div>
          <div className="user-plan-tier user-plan-total">{numberOfUsers}</div>
        </Space>
      )}
      {numberOfUsers <= 0 && (
        <div className="user-plan-description">
          Elige otro plan con más prestaciones, para llevar el rendimiento de tu
          organización a otro nivel
        </div>
      )}
    </div>
  );
};

export default UserPlanPill;
