import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

const formatDateTime = (dt) => {
  return dt && dayjs(dt).format('DD/MM/YYYY hh:mm A');
};

const formatDate = (d) => {
  return d && dayjs(d).format('DD/MM/YYYY');
};

const formatDateToISO = (d) => {
  return d && dayjs(d).toISOString();
};

const diffTime = (dt1, dt2) => {
  dayjs.extend(duration);
  if (!dt1) return null;
  const diff = dayjs(dt2).diff(dayjs(dt1), 'm');
  return diff < 60 ? `${diff} min` : `${dayjs.duration(diff, 'm').hours()} hr`;
};

const DATETIME = {
  formatDate,
  formatDateTime,
  formatDateToISO,
  diffTime,
};

export default DATETIME;
