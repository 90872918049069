import React from 'react';
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import '../ProjectCard.scss';
import { Space, Tooltip } from 'antd';
import BADGES from './badges';

const stopProp = (fn) => (e) => {
  e.stopPropagation();
  fn();
};

// TODO: Set default type based on parameterized types.
const ProjectCardContent = ({
  title = '',
  description = '',
  tooltip,
  color = 'BLUE',
  isDimmed = false,
  onClick,
  onEdit,
  onDelete,
}) => {
  const badge = BADGES[color];
  return (
    <Tooltip title={tooltip} arrow={false} placement="bottom">
      <div
        className={`project-card${isDimmed ? ' project-card-dimmed' : ''}`}
        style={{ backgroundColor: badge.backgroundColor }}
        onClick={onClick}
        title={title}
      >
        <Topbar
          title={title}
          color={badge.color}
          onEdit={onEdit}
          onDelete={onDelete}
        />
        <Content description={description} icon={badge.icon} />
      </div>
    </Tooltip>
  );
};

const Topbar = ({ title, color, onEdit, onDelete }) => {
  return (
    <div className={'project-card-topbar'}>
      <div className={'project-card-title'} style={{ color }}>
        {title}
      </div>
      <div className={'project-card-menu'}>
        <Space size="middle">
          {onEdit && (
            <EditFilled onClick={stopProp(onEdit)} style={{ color }} />
          )}
          {onDelete && (
            <DeleteFilled onClick={stopProp(onDelete)} style={{ color }} />
          )}
        </Space>
      </div>
    </div>
  );
};

const Content = ({ description, icon }) => {
  return (
    <div className={'project-card-content'}>
      <div className={'project-card-content-description'}>{description}</div>
      <img
        className={'project-card-content-badge'}
        src={icon}
        alt="badge icon"
      />
    </div>
  );
};

export default ProjectCardContent;
