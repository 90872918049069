import React from 'react';
import { useAPI } from 'modules/core/hooks';
import { useQuery } from '@tanstack/react-query';
import { Row, Col, Spin, Form, Input, Button, Select, Divider } from 'antd';
import {
  TitleExtra,
  // UploadImage
} from 'modules/core/components';

const generateOptions = (optionsList = []) =>
  optionsList.map((el) => ({
    value: el.id,
    label: el.name,
  }));

// TODO: Enable avatar and company logo update when available.
const UserProfile = () => {
  const api = useAPI();
  const { data: userDetail, isLoading: isLoadingUser } = useQuery({
    queryKey: ['Configuration::fetchUserDetail'],
    queryFn: api.fetchUserDetail,
  });

  const { data: jobs, isLoading: isLoadingJobs } = useQuery({
    queryKey: ['Configuration::fetchJobs'],
    queryFn: api.fetchJobOptions,
  });
  const jobsOptions = generateOptions(jobs);

  const { data: industries, isLoading: loadingIndustries } = useQuery({
    queryKey: ['Configuration::fetchIndustries'],
    queryFn: api.fetchIndustryOptions,
  });
  const industriesOptions = generateOptions(industries);

  const onFinish = (values) => {
    console.log('Success:', values);
  };

  if (isLoadingUser) return <Spin />;

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <TitleExtra title="Mi cuenta" />
      </Col>
      <Col span={16}>
        <Form
          name="basic"
          layout="vertical"
          requiredMark={false}
          initialValues={userDetail}
          onFinish={onFinish}
        >
          {/* <UploadImage
            title="Subí una foto tuya"
            description="Así te reconocen tus compañeros de equipo"
          />
          <Divider /> */}
          <Form.Item
            label={<b>Nombre</b>}
            name="customer_name"
            rules={[{ required: true, message: 'El campo es requerido' }]}
            style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            label={<b>Apellido</b>}
            name="customer_lastname"
            rules={[{ required: true, message: 'El campo es requerido' }]}
            style={{
              display: 'inline-block',
              width: '50%',
              margin: '0 0 0 8px',
            }}
          >
            <Input size="large" />
          </Form.Item>
          <Divider />
          <Form.Item
            label={<b>Dirección de correo electrónico</b>}
            name="customer_email"
            rules={[
              {
                required: true,
                message: 'El campo es requerido',
              },
              {
                type: 'email',
                message: 'El campo debe ser un email',
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          <Divider />
          <Form.Item label={<b>¿Qué rol tenes?</b>} name="job_id">
            <Select
              size="large"
              options={jobsOptions}
              loading={isLoadingJobs}
            />
          </Form.Item>
          <Form.Item
            label={<b>¿Industria a la que perteneces?</b>}
            name="customer_industry_id"
          >
            <Select
              size="large"
              options={industriesOptions}
              loading={loadingIndustries}
            />
          </Form.Item>
          <Divider />
          {/* <UploadImage
            title="Subí el logo de tu empresa "
            description="Así podes personalizar la plataforma"
          />
          <Divider /> */}
          <Form.Item wrapperCol={{ offset: 20, span: 8 }}>
            <Button
              type="primary"
              size="large"
              style={{ minWidth: 200 }}
              htmlType="submit"
            >
              Guardar
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default UserProfile;
