import React, { useState } from 'react';
import { useAPI } from 'modules/core/hooks';
import { Space, Typography } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { CardOptionsList } from 'modules/core/components';
import { LoginTitle } from 'modules/login/components';
import SkipNextButtons from './SkipNextButtons';

const StepTools = ({ onNext, onSkip }) => {
  const api = useAPI();
  const { data: tools, isLoading: isLoadingTools } = useQuery({
    queryKey: ['Configuration::fetchTools'],
    queryFn: api.fetchToolOptions,
  });
  const [selected, setSelected] = useState([]);
  const onSelect = (o) => {
    if (selected.some(({ id }) => id === o.id)) {
      setSelected(selected.filter(({ id }) => id !== o.id));
    } else setSelected(selected.concat(o));
  };
  return (
    <Space direction="vertical" size="large">
      <LoginTitle
        title="Queremos conocerte más"
        subtitle="Buscamos diseñar mejoras caracteristicas en base a tus intereses y conocimientos."
      />
      <Typography.Text strong>
        ¿Utilizás alguna de las siguientes herramientas?
      </Typography.Text>
      <CardOptionsList
        dataSource={tools}
        loading={isLoadingTools}
        selected={selected}
        onSelect={onSelect}
      />
      <SkipNextButtons
        disabled={!selected}
        onNext={() => onNext({ tools_id: selected?.map((s) => s.id) })}
        onSkip={onSkip}
      />
    </Space>
  );
};

export default StepTools;
