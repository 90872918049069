import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { QuestionCircleFilled } from '@ant-design/icons';
import { Modal, Button, Typography, Carousel, Spin } from 'antd';
import { useAPI } from 'modules/core/hooks';
import { HELPS } from 'modules/core/constants';
import FloatContainer from '../FloatContainer/FloatContainer';

import './HelpModal.scss';

const { Title, Paragraph } = Typography;

const HelpModal = ({ screen = '', ...restProps }) => {
  const api = useAPI();
  const [openHelp, setOpenHelp] = useState(false);
  const { data, isLoading } = useQuery({
    queryKey: ['Help::Fetch', { screen }],
    queryFn: api.fetchHelpByScreen,
  });
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  const renderVideo = (media) => {
    return (
      <video className="help-media-video" controls>
        <source src={media} type="video/mp4" />
        <source src={media} type="video/ogg" />
        No pudimos reproducir el video en tu navegador.
      </video>
    );
  };

  const renderImage = (media) => {
    return <img src={media} alt="help-media" className="help-media-image" />;
  };

  return (
    <>
      <FloatContainer>
        <Button
          onClick={() => setOpenHelp(true)}
          style={{ height: 60, width: 60, backgroundColor: 'white' }}
          type="secondary"
          shape="circle"
          icon={<QuestionCircleFilled style={{ fontSize: 50 }} />}
        />
      </FloatContainer>
      <Modal
        {...restProps}
        className="help-modal"
        open={openHelp}
        width={1100}
        centered={true}
        footer={null}
        onCancel={() => setOpenHelp(false)}
      >
        {isLoading && <Spin />}
        {!isLoading && (
          <Carousel className="help-modal-carousel" afterChange={onChange}>
            {data.map((el) => (
              <div key={el.id}>
                <Title level={4}>{el?.title}</Title>
                <div className="help-body">
                  <Paragraph style={{ whiteSpace: 'pre-wrap' }}>
                    {el?.body}
                  </Paragraph>
                  <div className="help-media">
                    {el.media_type === HELPS.TYPES.IMAGE &&
                      renderImage(el.media)}
                    {el.media_type === HELPS.TYPES.VIDEO &&
                      renderVideo(el.media)}
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        )}
      </Modal>
    </>
  );
};

export default HelpModal;
