import React, { useState } from 'react';
import PreadyAPI from './PreadyAPI';

const APIContext = React.createContext();

const APIProvider = ({ children, api = PreadyAPI }) => {
  const [API, setAPI] = useState(api);
  const [user, setUser] = useState(null);
  return (
    <APIContext.Provider value={{ API, setAPI, user, setUser }}>
      {children}
    </APIContext.Provider>
  );
};

export { APIContext };
export default APIProvider;
