import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Col } from 'antd';
import { useAPI } from 'modules/core/hooks';
import {
  FloatContainer,
  HelpModal,
  ProjectFilters,
  ProjectGrid,
  ProjectNameModal,
  TitleExtra,
} from 'modules/core/components';
import { HELPS, PATHS } from 'modules/core/constants';

// FIXME: filterSelected initial state, now is hardcoded
const StepProjectType = ({ onClickNext }) => {
  const api = useAPI();
  const navigate = useNavigate();
  const [openProjectNameModal, setOpenProjectNameModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [filterSelected, setFilterSelected] = useState();

  const selectFilter = (f) => {
    setFilterSelected((p) => f ?? p);
    setSelectedItem(null);
  };

  const { data: objectives, isLoadingObjectives } = useQuery({
    queryKey: ['Projects::fetchProjectsObjectives'],
    queryFn: api.fetchProjectsObjectives,
  });
  useEffect(() => {
    if (objectives) setFilterSelected(objectives[0]);
  }, [objectives]);

  const { data: types, isLoadingTypes } = useQuery({
    queryKey: ['Projects::fetchProjectsTypes'],
    queryFn: api.fetchProjectsTypes,
    enabled: !!objectives,
  });

  const filteredTypes = types
    ?.filter((t) => t.objective_id === filterSelected?.id)
    .map((f) => ({ ...f, color: f.objective.color }));

  // CreateProject Step
  const { mutate: createProject } = useMutation({
    mutationFn: api.createProject,
  });
  const handleProjectCreation = (project, handlers) => {
    setOpenProjectNameModal(false);
    createProject(
      { project },
      {
        ...handlers,
        onSuccess: (createdProject) => {
          handlers.onSuccess();
          navigate(`${PATHS.OBJECTIVES_PROJECTS}/${createdProject.project_id}`);
          // onClickNext({ projectId: createdProject.project_id });
        },
      }
    );
  };

  return (
    <>
      <Col span={24}>
        <TitleExtra title="Tipo de proyectos" level={4} />
      </Col>
      <Col span={24}>
        <ProjectFilters
          data={objectives}
          loading={isLoadingObjectives}
          selected={filterSelected}
          onSelect={selectFilter}
        />
      </Col>
      <Col span={24}>
        <ProjectGrid
          selectedItems={selectedItem ? [selectedItem?.project_id] : []}
          data={filteredTypes}
          loading={isLoadingTypes}
          onClick={setSelectedItem}
        />
      </Col>
      <Col span={4} offset={20}>
        <FloatContainer doubleInset>
          <Button
            type="primary"
            size="large"
            style={{ minWidth: 200 }}
            onClick={() => setOpenProjectNameModal(true)}
            disabled={!selectedItem?.project_id}
          >
            Siguiente
          </Button>
        </FloatContainer>
        <HelpModal screen={HELPS.SCREENS.NEW_PROJECT} />
      </Col>
      <ProjectNameModal
        open={openProjectNameModal}
        extraValues={{
          objective_id: filterSelected?.id,
          project_type_id: selectedItem?.project_id,
        }}
        onCreate={handleProjectCreation}
        onCancel={() => setOpenProjectNameModal(false)}
      />
    </>
  );
};

export default StepProjectType;
