import React from 'react';
import { Typography } from 'antd';

import './LoginTitle.scss';

const { Text } = Typography;

const LoginTitle = ({ title, subtitle }) => {
  return (
    <div>
      <Text level={2} className="pready-login-title">
        {title}
      </Text>
      <br />
      <Text>{subtitle}</Text>
    </div>
  );
};

export default LoginTitle;
