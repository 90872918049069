import blueBadge from 'assets/badges/blue-badge.svg';
import greenBadge from 'assets/badges/green-badge.svg';
import orangeBadge from 'assets/badges/orange-badge.svg';
import purpleBadge from 'assets/badges/purple-badge.svg';
import redBadge from 'assets/badges/red-badge.svg';
import newBadge from 'assets/badges/new-badge.svg';

const BADGES = {
  BLUE: {
    color: '#00D1FF',
    backgroundColor: '#050040',
    icon: blueBadge,
  },
  GREEN: {
    color: '#00FF29',
    backgroundColor: '#002406',
    icon: greenBadge,
  },
  ORANGE: {
    color: '#FF9900',
    backgroundColor: '#442900',
    icon: orangeBadge,
  },
  PURPLE: {
    color: '#ED20C0',
    backgroundColor: '#38002C',
    icon: purpleBadge,
  },
  RED: {
    color: '#FF003D',
    backgroundColor: '#36010E',
    icon: redBadge,
  },
  NEW: {
    color: '#B2B2B2',
    backgroundColor: '#EDEDED',
    icon: newBadge,
  },
};

export default BADGES;
