import React from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Carousel, Spin } from 'antd';

import './ProjectFilters.scss';

const CarouselArrowPrev = ({ className, onClick }) => (
  <LeftOutlined
    className={`${className} project-filters-arrow`}
    onClick={onClick}
  />
);

const CarouselArrowNext = ({ className, onClick }) => (
  <RightOutlined
    className={`${className} project-filters-arrow`}
    onClick={onClick}
  />
);

const ProjectFilters = ({ data = [], loading, selected, onSelect }) => {
  if (loading) {
    return <Spin spinning={loading} />;
  }

  return (
    <div className="project-filters">
      <Carousel
        className="project-filters-carousel"
        slidesToShow={6}
        slidesToScroll={6}
        initialSlide={0}
        infinite={false}
        dots={false}
        arrows={true}
        nextArrow={<CarouselArrowNext />}
        prevArrow={<CarouselArrowPrev />}
        responsive={[
          {
            breakpoint: 1660,
            settings: { slidesToShow: 6, slidesToScroll: 6 },
          },
          {
            breakpoint: 1446,
            settings: { slidesToShow: 5, slidesToScroll: 5 },
          },
          {
            breakpoint: 1367,
            settings: { slidesToShow: 4, slidesToScroll: 4 },
          },
          {
            breakpoint: 880,
            settings: { slidesToShow: 3, slidesToScroll: 3 },
          },
        ]}
      >
        {data.map((el) => (
          <div
            key={el?.id}
            className={`project-filters-card ${
              el?.id === selected?.id ? 'selected' : ''
            }`}
            onClick={() =>
              el?.id !== selected?.id ? onSelect(el) : onSelect(null)
            }
          >
            <span className="project-filters-card-title">
              {el?.description}
            </span>
            <span className="project-filters-card-subtitle">
              {el?.subtitle}
            </span>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ProjectFilters;
