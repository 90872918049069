const INACTIVO = 'inactive';
const ACTIVO = 'active';
const COMPLETO = 'complete';

const STATUS = { INACTIVO, ACTIVO, COMPLETO };
const STATUS_LIST = Object.keys(STATUS);

const STATUS_FORMATTED = {
  [INACTIVO]: {
    title: 'Inactivo',
    backgroundColor: '#f2f4f7',
    color: '#344054',
  },
  [ACTIVO]: { title: 'Activo', backgroundColor: '#fff2b7', color: '#ff8b13' },
  [COMPLETO]: {
    title: 'Completo',
    backgroundColor: '#ecfdf3',
    color: '#12b76a',
  },
};

const OBJECTIVES = {
  STATUS,
  STATUS_LIST,
  STATUS_FORMATTED,
};

export default OBJECTIVES;
