import React, { useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Space, Upload, Typography, message } from 'antd';

const { Text } = Typography;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

// TODO: Finish upload avatar with endpoint in action prop and use image from user if has initialValue
const UploadImage = ({ title = '', description = '' }) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Solo puede subir archivos con formato JPG/PNG');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('La imagen tiene que ser menor a 2MB');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Subir foto</div>
    </div>
  );

  const avatarPreview = (
    <img
      src={imageUrl}
      alt="avatar"
      style={{ width: '100%', borderRadius: '50%' }}
    />
  );

  return (
    <Space>
      <Upload
        name="avatar"
        listType="picture-circle"
        showUploadList={false}
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl && avatarPreview}
        {!imageUrl && uploadButton}
      </Upload>
      <Space direction="vertical">
        <b>{title}</b>
        <Text type="secondary">{description}</Text>
      </Space>
    </Space>
  );
};

export default UploadImage;
