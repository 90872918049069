import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Checkbox, Form, Input, Select, Space } from 'antd';
import { useAPI } from 'modules/core/hooks';
import { ButtonSecondary } from 'modules/core/components';
import { LoginTitle } from 'modules/login/components';

const StepData = ({ onNext }) => {
  const api = useAPI();
  const { data: industries, isLoading: isLoadingIndustries } = useQuery({
    queryKey: ['Configuration::fetchIndustries'],
    queryFn: api.fetchIndustryOptions,
  });
  return (
    <Space direction="vertical" size="large">
      <LoginTitle
        title="Bienvenido"
        subtitle="Vas a experimentar el análisis predictivo al alcance de todos"
      />
      <Form
        name="basic"
        layout="vertical"
        requiredMark={false}
        onFinish={onNext}
      >
        <Form.Item
          name="user_name"
          rules={[{ required: true, message: 'Ingrese nombre' }]}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        >
          <Input size="large" placeholder="Nombre" />
        </Form.Item>
        <Form.Item
          name="user_lastname"
          rules={[{ required: true, message: 'Ingrese apellido' }]}
          style={{ display: 'inline-block', width: '50%', margin: '0 0 0 8px' }}
        >
          <Input size="large" placeholder="Apellido" />
        </Form.Item>
        <Form.Item
          name="customer_company_name"
          rules={[{ required: true, message: 'Ingrese compañía' }]}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        >
          <Input size="large" placeholder="Empresa" />
        </Form.Item>
        <Form.Item
          name="customer_industry_id"
          rules={[{ required: true, message: 'Seleccione rubro' }]}
          style={{ display: 'inline-block', width: '50%', margin: '0 0 0 8px' }}
        >
          <Select
            size="large"
            placeholder="Rubro"
            loading={isLoadingIndustries}
            options={industries?.map((r) => ({
              value: r.id,
              label: r.name,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="user_email"
          rules={[
            { required: true, message: 'Ingrese email' },
            { type: 'email', message: 'El campo debe ser un email' },
          ]}
        >
          <Input size="large" placeholder="Ingrese su correo electronico" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: 'Ingrese contraseña' },
            {
              message:
                'Recorda: Más de 8 caracteres, al menos una mayúscula, un número y un caracter especial (#$@!%&*?)',
              pattern:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,}$/,
            },
          ]}
        >
          <Input.Password size="large" placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <ButtonSecondary block type="primary" size="large" htmlType="submit">
            Registrarme
          </ButtonSecondary>
        </Form.Item>
        <Form.Item
          name="tocs"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        'Debe aceptar los términos y condiciones para continuar'
                      )
                    ),
            },
          ]}
        >
          <Checkbox>Aceptar términos y condiciones</Checkbox>
        </Form.Item>
      </Form>
    </Space>
  );
};

export default StepData;
