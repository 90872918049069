import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Popover, Progress, Space } from 'antd';
import {
  Cog6ToothIcon,
  ArrowRightOnRectangleIcon,
} from '@heroicons/react/20/solid';
import { PATHS } from 'modules/core/constants';
import userCircle from 'assets/icons/user-circle.svg';
import arrowDown from 'assets/icons/arrow-down.svg';
import './UserInfoWidget.scss';

const UserInfoWidget = ({ detail, sub, loading }) => {
  const [open, setOpen] = useState(false);
  if (loading) return null;
  return (
    <Space>
      <img className="userinfo-widget-icon" src={userCircle} alt="user icon" />
      <Space direction="vertical">
        <span>{detail?.customer_fullname}</span>
        <span>{detail?.customer_email}</span>
      </Space>
      <Popover
        color={'#000000'}
        placement="bottomRight"
        arrow={false}
        trigger="click"
        open={open}
        onOpenChange={setOpen}
        content={
          <UserInfoWidgetContent
            plan={sub}
            closePopover={() => setOpen(false)}
          />
        }
      >
        <img
          className="userinfo-widget-icon dropdown"
          src={arrowDown}
          alt="dropdown arrow icon"
        />
      </Popover>
    </Space>
  );
};

const UserInfoWidgetContent = ({ plan, closePopover }) => {
  return (
    <div className="userinfo-widget-content">
      <div className="userinfo-widget-content-title">
        <Cog6ToothIcon width={20} />
        Configuración Cuenta
      </div>
      <PlanInfo plan={plan} onNavigate={closePopover} />
      <PlanLimits plan={plan} />
      <Logout onLogout={closePopover} />
    </div>
  );
};

const PlanInfo = ({ plan, onNavigate }) => {
  const navigate = useNavigate();
  return (
    <div className="plan-info">
      <div className="plan-info-name">{plan?.subscription_name}</div>
      {plan?.subscription_upgradable && (
        <Button
          className="plan-info-upgrade"
          type="text"
          shape="circle"
          onClick={() => {
            navigate(PATHS.CONFIGURATION_PLAN);
            onNavigate();
          }}
        >
          Mejora tu plan
        </Button>
      )}
    </div>
  );
};

const PlanLimits = ({ plan }) => {
  const { storage, users } = plan?.subscription_limits;
  return (
    <div className="plan-limits">
      <div className="plan-limits-title">Límites del Plan</div>
      <PlanMeter title="Almacenamiento" meter={storage} unit="GB" />
      <PlanMeter title="Ejecuciones diarias" meter={users} />
    </div>
  );
};

const PlanMeter = ({ meter, title, unit = '' }) => (
  <div className="plan-limits-meter">
    <div className="plan-limits-text ">
      <div>{title}</div>
      <div>{`${meter.used} / ${meter.total} ${unit}`}</div>
    </div>
    <Progress
      trailColor={'#FFF'}
      strokeColor={'#ed20eb'}
      showInfo={false}
      percent={(meter.used * 100) / meter.total}
    />
  </div>
);

const Logout = ({ onLogout }) => {
  const navigate = useNavigate();
  return (
    <div className="userinfo-widget-logout-container">
      <Button
        className="userinfo-widget-logout"
        type="text"
        shape="circle"
        onClick={() => {
          onLogout();
          navigate(PATHS.LOGOUT);
        }}
      >
        <ArrowRightOnRectangleIcon width={20} />
        Cerrar Sesión
      </Button>
    </div>
  );
};

export default UserInfoWidget;
