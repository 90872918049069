const HOME = '/';
const LOGIN = '/login';
const LOGOUT = '/logout';
const REGISTER = '/registrarse';
const PASSWORD_RECOVERY = '/recuperar-password';
const DEMOS = '/demostraciones';
const OBJECTIVES = '/objetivos';
const OBJECTIVES_PROJECTS = `${OBJECTIVES}/proyectos`;
const OBJECTIVES_PROJECTS_DEMOS = `${OBJECTIVES}/demos`;
const CONFIGURATION = '/configuraciones';
const CONFIGURATION_PROFILE = `${CONFIGURATION}/perfil`;
const CONFIGURATION_PLAN = `${CONFIGURATION}/plan`;
const CONFIGURATION_TEAM = `${CONFIGURATION}/equipo`;
const NOTIFICATIONS = `/notificaciones`;

const PATHS = {
  HOME,
  LOGIN,
  LOGOUT,
  REGISTER,
  PASSWORD_RECOVERY,
  DEMOS,
  OBJECTIVES,
  OBJECTIVES_PROJECTS,
  OBJECTIVES_PROJECTS_DEMOS,
  CONFIGURATION,
  CONFIGURATION_PROFILE,
  CONFIGURATION_PLAN,
  CONFIGURATION_TEAM,
  NOTIFICATIONS,
};

export default PATHS;
