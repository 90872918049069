import React from 'react';
import { Button } from 'antd';

const SkipNextButtons = ({ onNext, onSkip, disabled }) => {
  return (
    <div className="pready-register-stepper-next-buttons">
      <Button size="large" style={{ minWidth: 200 }} onClick={() => onSkip()}>
        Saltear
      </Button>
      <Button
        type="primary"
        size="large"
        style={{ minWidth: 200 }}
        disabled={disabled}
        onClick={() => onNext()}
      >
        Avanzar
      </Button>
    </div>
  );
};

export default SkipNextButtons;
