import React from 'react';
import { Col, Row, Typography } from 'antd';

import './TitleExtra.scss';

const { Title } = Typography;

const TitleExtra = ({ title, extra, level = 3, topAligned = false }) => (
  <Row className={`title-extra ${topAligned && 'title-extra-top'}`}>
    <Col span={extra ? 10 : 24}>
      <Title className="title-header" level={level}>
        {title}
      </Title>
    </Col>
    {extra && (
      <Col
        span={14}
        className={`title-extra-extra ${topAligned && 'title-extra-extra-top'}`}
      >
        {extra}
      </Col>
    )}
  </Row>
);

export default TitleExtra;
