import React from 'react';
import { useAPI } from 'modules/core/hooks';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, Link } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  message,
  Space,
  Typography,
  Carousel,
} from 'antd';
import { PATHS } from 'modules/core/constants';
import { LOCAL_STORAGE } from 'modules/core/utils';
import { ButtonSecondary } from 'modules/core/components';
import { LoginTitle } from '../components';
import logoWhite from 'assets/logo-pready-white.svg';

import './Login.scss';

const { Text } = Typography;

const Login = () => {
  const api = useAPI();
  const navigate = useNavigate();

  const { data: review, isLoading: isLoadingReview } = useQuery({
    queryKey: ['Login::LoginReview'],
    queryFn: api.fetchLoginReview,
    select: ({ data }) => data[0],
  });

  const { mutate: login, isLoading } = useMutation({ mutationFn: api.login });

  const handleOnSuccess = ({ token }) => {
    api.withAuthUser(token);
    LOCAL_STORAGE.TOKEN.set(token);
    navigate(PATHS.HOME);
  };

  const handleOnError = ({ message: error }) => {
    console.log('ERROR', error);
    switch (error) {
      case '404':
        message.error('Credenciales inválidas', 5);
        break;
      default:
        message.error('Error al conectarse al servidor');
    }
  };

  const onFinish = (values) => {
    login(
      { ...values },
      { onSuccess: handleOnSuccess, onError: handleOnError }
    );
  };

  const renderStatisticsInfo = () => (
    <div className="login-statistics">
      <div className="login-statistics-value">{review?.title}</div>
      <div className="login-statistics-content">{review?.description}</div>
    </div>
  );

  const renderReviewCard = () => (
    <Carousel className="login-review-carousel">
      {review?.reviews.map((r) => (
        <div key={r.id}>
          <div className="login-review-card">
            <div className="login-review-content">{r.description}</div>
            <Space className="login-review-author-container">
              <div className="login-review-author-img" />
              <Space direction="vertical">
                <div className="login-review-author">{r.author}</div>
                <div></div>
              </Space>
            </Space>
          </div>
        </div>
      ))}
    </Carousel>
  );

  const renderForm = () => (
    <Form
      name="basic"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
    >
      <Form.Item
        label="Email"
        name="username"
        rules={[
          { required: true, message: 'El campo es requerido' },
          { type: 'email', message: 'El campo debe ser un email' },
        ]}
      >
        <Input size="large" placeholder="Ingrese su correo electronico" />
      </Form.Item>
      <Form.Item
        label="Contraseña"
        name="password"
        rules={[{ required: true, message: 'El campo es requerido' }]}
      >
        <Input.Password size="large" placeholder="Ingrese su constraseña" />
      </Form.Item>
      <span className="pready-login-forgot-password">
        <Link to={PATHS.PASSWORD_RECOVERY} className="pready-login-link">
          ¿Olvidaste tu contraseña?
        </Link>
      </span>
      <Form.Item>
        <ButtonSecondary
          block
          type="primary"
          size="large"
          htmlType="submit"
          loading={isLoading}
        >
          Ingresar
        </ButtonSecondary>
      </Form.Item>
    </Form>
  );

  const renderFooter = () => (
    <span className="pready-login-form-footer">
      <Text type="secondary">¿No tienes cuenta? </Text>
      <Link to={PATHS.REGISTER} className="pready-login-link">
        Registrate
      </Link>
    </span>
  );

  return (
    <Row className="pready-login">
      <Col xs={0} lg={12} className="pready-login-sider">
        <div className="login-info-container">
          <div>
            <img src={logoWhite} alt="Logo Pready" width={135} />
            {!isLoadingReview && renderStatisticsInfo()}
          </div>
          {!isLoadingReview && renderReviewCard()}
        </div>
      </Col>
      <Col xs={24} lg={12} className="pready-login-form-container">
        <Space direction="vertical" size="large">
          <LoginTitle
            title="Bienvenido"
            subtitle="Vas a experimentar el análisis predictivo al alcance de todos"
          />
          {renderForm()}
          {renderFooter()}
        </Space>
      </Col>
    </Row>
  );
};

export default Login;
