import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button, Col } from 'antd';
import { useAPI } from 'modules/core/hooks';
import {
  ButtonSecondary,
  FileHistory,
  FloatContainer,
  HelpModal,
  TitleExtra,
} from 'modules/core/components';
import { HELPS } from 'modules/core/constants';
import DataExamplesModal from './components/DataExamplesModal';

const StepAddData = ({ data, onClickNext }) => {
  const api = useAPI();
  const [uploadData, setUploadData] = useState(false);
  const {
    data: files,
    isLoading: loadingFiles,
    refetch,
  } = useQuery({
    queryKey: ['Files::History'],
    queryFn: api.fetchFilesHistory,
  });

  const [selectedRows, setSelectedRows] = useState({ keys: [], rows: [] });
  const rowSelection = {
    selectedRowKey: selectedRows.keys,
    type: 'radio',
    onChange: (keys, rows) => setSelectedRows({ keys, rows }),
  };

  const handleOnNext = () => {
    const selectedFile = selectedRows.rows.map(({ name }) => name)[0];
    onClickNext({ ...data, projectId: data.projectId, fileName: selectedFile });
  };

  return (
    <>
      <Col span={24}>
        <TitleExtra
          title="Archivos subidos disponibles"
          level={4}
          extra={
            <ButtonSecondary type="primary" onClick={() => setUploadData(true)}>
              Cargar datos
            </ButtonSecondary>
          }
        />
      </Col>
      <Col span={24}>
        <FileHistory
          rowSelection={rowSelection}
          data={files}
          loading={loadingFiles}
          refetch={refetch}
        />
      </Col>
      <Col span={24}>
        <FloatContainer doubleInset>
          <Button
            type="primary"
            size="large"
            style={{ minWidth: 200 }}
            onClick={handleOnNext}
            disabled={!selectedRows.keys.length > 0}
          >
            Siguiente
          </Button>
        </FloatContainer>
        <HelpModal screen={HELPS.SCREENS.ADD_DATA_STEP} />
      </Col>
      <DataExamplesModal
        open={uploadData}
        onCancel={() => setUploadData(false)}
        onUpload={() => {
          setUploadData(false);
          refetch();
        }}
      />
    </>
  );
};

export default StepAddData;
