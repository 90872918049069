import React from 'react';
import { Table as TableAnt } from 'antd';
import './Table.scss';

const Table = ({
  className,
  pagination = false,
  showTotal = false,
  ...props
}) => {
  return (
    <TableAnt
      {...props}
      bordered
      size="small"
      showSorterTooltip={false}
      className={`pready-table ${className}`}
      pagination={
        pagination && {
          showSizeChanger: true,
          position: ['bottomRight'],
          defaultPageSize: 10,
          pageSizeOptions: [10, 20, 50, 100],
          ...pagination,
        }
      }
      footer={() =>
        showTotal && pagination?.total ? `- ${pagination.total} items -` : ''
      }
    />
  );
};

export default Table;
