import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Rate, Table, Typography, message, Spin } from 'antd';
import { useAPI } from 'modules/core/hooks';
import { DATETIME } from 'modules/core/utils';
import { OBJECTIVES } from 'modules/core/constants';
import { SimpleModal } from 'modules/core/components';
import { CreateAccionable, StatusDropdown } from '../';

const { Text } = Typography;

const initialDeleteAccionable = { open: false, actionId: null };

const ActionsPanel = ({
  projectId,
  isDemo = false,
  insightId,
  openCreateModal,
  onCreateModalOk = () => {},
  onRefetchInsights = () => {},
}) => {
  const api = useAPI();
  const [deleteAccionableModal, setAccionableDeleteModal] = useState(
    initialDeleteAccionable
  );

  const {
    data: actions,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      'Project::fetchProjectInsights',
      { projectId, insightId, isDemo },
    ],
    queryFn: api.fetchProjectInsightsActions,
    select: (d) => d.Status || [],
  });

  const { mutate: createAction, isLoading: isCreatingAction } = useMutation({
    mutationFn: api.createProjectInsightsAction,
  });
  const handleOnCreate = ({ what, where, when }) => {
    createAction(
      {
        insight_id: insightId,
        action_desc: `Ofrecer ${what?.description} por ${
          where?.description
        } para el ${DATETIME.formatDateTime(when)}`,
        execution_date: DATETIME.formatDateToISO(when),
      },
      {
        onSuccess: () => {
          refetch();
          onRefetchInsights();
          onCreateModalOk();
        },
        onError: () =>
          message.error('No se ha podido crear la acción solicitada.'),
      }
    );
  };

  const { mutate: updateAction } = useMutation({
    mutationFn: api.updateProjectInsightsAction,
  });
  const handleOnUpdate = ({ actionId, status }) =>
    updateAction(
      { actionId, status },
      {
        onSuccess: refetch,
        onError: () => message.error('No se ha podido actualizar el estado.'),
      }
    );

  const { mutate: deleteAction, isLoading: isDeleting } = useMutation({
    mutationFn: api.deleteProjectInsightsAction,
  });
  const handleOnDelete = ({ onSuccess, onError }) =>
    deleteAction(
      { actionId: deleteAccionableModal.actionId },
      { onSuccess, onError }
    );

  const columns = [
    { dataIndex: 'action_desc', title: 'Acciones' },
    {
      dataIndex: 'status',
      title: 'Status',
      width: 150,
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (el, record) => (
        <StatusDropdown
          status={el}
          disabled={isDemo}
          onClick={(status) =>
            handleOnUpdate({ actionId: record.action_id, status })
          }
        />
      ),
    },
    {
      dataIndex: 'actions',
      width: 140,
      render: (_, record) => {
        if (isDemo) return null;

        if (record.status === OBJECTIVES.STATUS.COMPLETO) {
          return <Rate style={{ fontSize: 15 }} />;
        } else {
          return (
            <Button
              type="text"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() =>
                setAccionableDeleteModal({
                  open: true,
                  actionId: record.action_id,
                })
              }
            />
          );
        }
      },
    },
  ];

  if (isLoading) return <Spin />;

  return (
    <>
      {actions.length > 0 && (
        <Table
          rowKey="action_id"
          loading={isLoading}
          dataSource={actions}
          columns={columns}
          pagination={false}
        />
      )}
      {actions.length <= 0 && <span>No hay acciones definidas</span>}
      <CreateAccionable
        open={openCreateModal}
        onCreate={handleOnCreate}
        onCancel={onCreateModalOk}
        loading={isCreatingAction}
      />
      <SimpleModal
        title="Borrar accionable"
        titleIcon={ExclamationCircleOutlined}
        open={deleteAccionableModal.open}
        okText="Borrar"
        onOk={handleOnDelete}
        confirmLoading={isDeleting}
        onSuccess={() => {
          refetch();
          onRefetchInsights();
          setAccionableDeleteModal(initialDeleteAccionable);
        }}
        onSuccessText="Acción eliminada con éxito."
        onCancel={() => setAccionableDeleteModal(initialDeleteAccionable)}
      >
        <Text type="secondary">Esta acción es irreversible.</Text>
      </SimpleModal>
    </>
  );
};

export default ActionsPanel;
