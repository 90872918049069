import React from 'react';
import { Spin } from 'antd';
import { NavLink } from 'react-router-dom';
import { PATHS } from 'modules/core/constants';

const ProjectStatusContainer = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      {children}
    </div>
  );
};

const ProjectStatusError = ({ project, hasInvalidState = false }) => {
  return (
    <ProjectStatusContainer>
      <h1>{project?.project_name}</h1>
      {!hasInvalidState && <p>Ha fallado la predicción del proyecto.</p>}
      {hasInvalidState && (
        <p>Ocurrio un error al validar el estado del proyecto.</p>
      )}
      <NavLink to={PATHS.HOME}>Volver al inicio</NavLink>
    </ProjectStatusContainer>
  );
};

const ProjectStatusErrorNotFound = () => {
  return (
    <ProjectStatusContainer>
      <p>El proyecto solicitado no pudo ser encontrado.</p>
      <NavLink to={PATHS.HOME}>Volver al inicio</NavLink>
    </ProjectStatusContainer>
  );
};

const ProjectStatusRunning = ({ project }) => {
  return (
    <ProjectStatusContainer>
      <h1>{project?.project_name}</h1>
      <Spin
        tip={
          <div>
            Tu proyecto aún esta ejecutando la predicción.
            <br />
            Por favor, vuelve a verificar en unos minutos.
          </div>
        }
      />
    </ProjectStatusContainer>
  );
};

const ProjectStatus = {
  Error: ProjectStatusError,
  ErrorNotFound: ProjectStatusErrorNotFound,
  Running: ProjectStatusRunning,
};
export default ProjectStatus;
