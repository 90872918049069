const TOKEN = {
  KEY: 'Pready::Token',
  set(token) {
    return localStorage.setItem(this.KEY, token);
  },
  remove() {
    return localStorage.removeItem(this.KEY);
  },
  get() {
    return localStorage.getItem(this.KEY);
  },
};

const LOCAL_STORAGE = {
  TOKEN,
};

export default LOCAL_STORAGE;
