import React from 'react';

import './FloatContainer.scss';

const FloatContainer = ({ children, doubleInset = false }) => {
  return (
    <div className={`float-container ${doubleInset && 'double-inset'}`}>
      {children}
    </div>
  );
};

export default FloatContainer;
