import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Button, Popover } from 'antd';
import { BellIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { DATETIME } from 'modules/core/utils';
import { PATHS } from 'modules/core/constants';
import './NotificationWidget.scss';

const NotificationWidget = ({ notifications = [] }) => {
  const [open, setOpen] = useState(false);
  return (
    <Popover
      color={'#000000'}
      placement="bottomRight"
      arrow={false}
      trigger="click"
      open={open}
      onOpenChange={setOpen}
      content={
        <NotificationWidgetContent
          notifications={notifications}
          closeFn={() => setOpen(false)}
        />
      }
    >
      <div className="notification-widget-icon-container">
        <Badge
          count={notifications.filter((n) => !n.is_read).length}
          size="small"
          color="#ed20eb"
          offset={[-5, 5]}
        >
          <BellIcon className="notification-widget-icon" />
        </Badge>
      </div>
    </Popover>
  );
};

const NotificationWidgetContent = ({ notifications, closeFn }) => {
  return (
    <div className="notification-widget-content">
      <div className="notification-widget-content-title">
        Tus notificaciones
      </div>
      {notifications.length > 0 && (
        <Notifications notifications={notifications} closeFn={closeFn} />
      )}
      {!notifications.length && (
        <div className="notification-widget-content-empty">
          No hay notificaciones.
        </div>
      )}
    </div>
  );
};

const Notifications = ({ notifications, closeFn }) => (
  <>
    {notifications?.slice(0, 3).map((n) => (
      <Notification
        key={n.message_id}
        text={n.message}
        date={n.fecha_creacion}
      />
    ))}
    <ViewAll onNavigate={closeFn} />
  </>
);

const Notification = ({ text, date }) => {
  return (
    <div className="notification-widget-notification">
      <span className="notification-widget-notification-text">{text}</span>
      <span className="notification-widget-notification-date">
        {DATETIME.diffTime(date)}
      </span>
    </div>
  );
};

const ViewAll = ({ onNavigate }) => {
  const navigate = useNavigate();
  return (
    <div className="notification-widget-viewAll-container">
      <Button
        className="notification-widget-viewAll"
        type="text"
        shape="circle"
        onClick={() => {
          navigate(PATHS.NOTIFICATIONS);
          onNavigate();
        }}
      >
        Ver todas
        <ChevronRightIcon width={12} />
      </Button>
    </div>
  );
};

export default NotificationWidget;
