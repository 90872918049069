import { useContext } from 'react';
import { APIContext } from 'modules/api';

// NOTE: When the application grows and has multiple roles, it may be necessary to implement a useClearAPI
const useAPI = () => {
  const { API } = useContext(APIContext);
  return API;
};

export default useAPI;
