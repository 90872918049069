import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Row, Col, message } from 'antd';
import logoWhite from 'assets/logo-pready-white.svg';
import background from 'assets/images/register_bg.png';
import { useAPI } from 'modules/core/hooks';
import RegisterStepper from './components/RegisterStepper';

import './Register.scss';

const SUBMIT_STEP = 3;

const Register = () => {
  const api = useAPI();
  const [currentStep, setCurrentStep] = useState(0);
  const [registerData, setRegisterData] = useState({});
  const { mutate: signUp } = useMutation({
    mutationFn: api.signUp,
  });

  const handleOnError = ({ message: error }) => {
    switch (error) {
      default:
        message.error('Error al crear el usuario');
    }
  };

  const onNext = (newData) => {
    const data = Object.assign({}, registerData, newData);
    setRegisterData(data);
    if (currentStep === SUBMIT_STEP) {
      signUp(data, {
        onSuccess: () => setCurrentStep((s) => s + 1),
        onError: handleOnError,
      });
    } else setCurrentStep((s) => s + 1);
  };

  return (
    <Row className="pready-register">
      <Col
        xs={0}
        lg={12}
        className="pready-register-sider"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="register-sider-content">
          <img src={logoWhite} alt="Logo Pready" width={135} />
          <PicCredits />
        </div>
      </Col>
      <Col xs={24} lg={12} className="pready-register-main">
        <RegisterStepper
          currentStep={currentStep}
          onNext={onNext}
          onSkip={onNext}
        />
      </Col>
    </Row>
  );
};

const PicCredits = () => (
  <div>
    Art by{' '}
    <a href="https://playgroundai.com/profile/clc9eggly024ds601gt6fwx5o">
      Rezi Patrik
    </a>{' '}
    in Playgroundai
  </div>
);

export default Register;
