import React from 'react';
import { useAPI } from 'modules/core/hooks';
import { useQuery } from '@tanstack/react-query';
import { Row, Col, Spin } from 'antd';
import {
  // PlanFeatures,
  TitleExtra,
  UserPlanPill,
} from 'modules/core/components';

// TODO: Embeber plataforma de pago
// TODO: Enable planData when plan data is sanitized and available.
const UserPlan = () => {
  const api = useAPI();
  const { data: userSubscription, isLoading: isLoadingSub } = useQuery({
    queryKey: ['User::fetchUserSubscription'],
    queryFn: api.fetchUserSubscription,
  });
  // const { data: planData } = useQuery({
  //   queryKey: ['Configuration::fetchPlans'],
  //   queryFn: api.fetchPlans,
  //   select: ({ data }) => data,
  // });

  if (isLoadingSub) return <Spin />;

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <TitleExtra title="Planes" />
      </Col>
      <Col span={24}>
        <UserPlanPill
          tier={userSubscription?.subscription_name}
          numberOfUsers={userSubscription?.subscription_limits.users.total}
        />
      </Col>
      {/* {planData?.map((plan) => (
        <Col span={24 / planData.length} key={plan.id}>
          <PlanFeatures plan={plan} />
        </Col>
      ))} */}
    </Row>
  );
};

export default UserPlan;
