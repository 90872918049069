import APIPrototype from './APIPrototype';
import API_PARSER from './utils/apiParser';

const API = Object.create(APIPrototype);

API.login = function ({ username, password }) {
  return API.fetchAPIAndHandle('/login', {
    method: 'POST',
    headers: API.getJSONHeaders(),
    body: JSON.stringify({ username, password }),
  });
};

API.logout = function () {
  return API.fetchAPIAndHandle('/logout', {
    method: 'POST',
    headers: API.getJSONHeaders(),
    body: JSON.stringify({}),
  });
};

API.signUp = function (registerData) {
  return API.fetchAPIAndHandle('/user/', {
    method: 'POST',
    headers: API.getJSONHeaders(),
    body: JSON.stringify(registerData),
  });
};

API.fetchLoginReview = function () {
  return API.fetchAPIAndHandle('/staticData/login/reviews', {
    method: 'GET',
    headers: API.getJSONHeaders(),
  });
};

API.fetchResetPasswordInfo = function () {
  return API.fetchAPIAndHandle('/staticData/login/resetPasswordInfo', {
    method: 'GET',
    headers: API.getJSONHeaders(),
  });
};

// TODO: Review when endpoint exists
API.passwordRecovery = function ({ email }) {
  return API.fetchAPIAndHandle('/passwordRecovery', {
    method: 'POST',
    headers: API.getJSONHeaders(),
    body: JSON.stringify({ email }),
  });
};

// ++ Helps ++
API.fetchHelpByScreen = function ({ queryKey }) {
  const [, { screen }] = queryKey;
  return API.fetchAPIAndHandle(`/staticData/screensHelp?screen=${screen}`, {
    method: 'GET',
    headers: API.getAuthJSONHeaders(),
  });
};

// ++ Projects ++
API.fetchProjects = function ({ queryKey }) {
  const [, { isDemo } = {}] = queryKey;
  const url = isDemo ? '/projects/demos' : '/projects/';
  return API.fetchAPIAndHandle(`${url}`, {
    method: 'GET',
    headers: API.getAuthJSONHeaders(),
  }).then(({ proyectos }) => API_PARSER.parseProject(proyectos));
};

API.createProject = function ({ project }) {
  return API.fetchAPIAndHandle(`/projects/`, {
    method: 'POST',
    headers: API.getAuthJSONHeaders(),
    body: JSON.stringify(project),
  });
};

API.fetchProjectDetail = function ({ queryKey }) {
  const [, { projectId, isDemo }] = queryKey;
  const url = isDemo ? '/project/demos' : '/project';

  return API.fetchAPIAndHandle(`${url}/${projectId}`, {
    method: 'GET',
    headers: API.getAuthJSONHeaders(),
  }).then((proyecto) => API_PARSER.parseProject(proyecto));
};

API.deleteProject = function ({ projectId }) {
  return API.fetchAPIAndHandle(`/projects?project_id=${projectId}`, {
    method: 'DELETE',
    headers: API.getAuthJSONHeaders(),
  });
};

API.fetchFilesHistory = function () {
  return API.fetchAPIAndHandle(`/files/history/`, {
    method: 'GET',
    headers: API.getAuthJSONHeaders(),
  });
};

API.uploadFile = function ({ inputFile }) {
  return API.fetchAPIAndHandle(`/files/`, {
    method: 'POST',
    headers: API.getAuthHeaders(),
    body: inputFile,
  }).then(API.handleBlobOnerror);
};

API.deleteFile = function ({ fileName }) {
  return API.fetchAPIAndHandle(`/files/?file=${fileName}`, {
    method: 'DELETE',
    headers: API.getAuthHeaders(),
  });
};

API.fetchDataTypes = function () {
  return API.fetchAPIAndHandle(`/contextual/entities`, {
    method: 'GET',
    headers: API.getAuthJSONHeaders(),
  });
};

API.fetchFilesExamples = function () {
  return API.fetchAPIAndHandle(`/staticData/project/fileExamples`, {
    method: 'GET',
    headers: API.getAuthJSONHeaders(),
  });
};

API.fetchFileInfo = function ({ queryKey }) {
  const [, { fileName, projectId }] = queryKey;
  return API.fetchAPIAndHandle(`/files/diagnostic/`, {
    method: 'POST',
    headers: API.getAuthJSONHeaders(),
    body: JSON.stringify({ file_name: fileName, project_id: projectId }),
  });
};

API.fetchFileDiagnostic = function ({ queryKey }) {
  const [, { fileName, projectId, selectedColumns }] = queryKey;
  return API.fetchAPIAndHandle(`/files/step3`, {
    method: 'POST',
    headers: API.getAuthJSONHeaders(),
    body: JSON.stringify({
      file_name: fileName,
      project_id: projectId,
      cols: selectedColumns,
    }),
  });
};

API.runProjectPrediction = function (projectId) {
  return API.fetchAPIAndHandle(`/files/job?project_id=${projectId}`, {
    method: 'POST',
    headers: API.getAuthJSONHeaders(),
  });
};

API.fetchProjectsObjectives = function () {
  return API.fetchAPIAndHandle(`/staticData/project/objectives`, {
    method: 'GET',
    headers: API.getAuthJSONHeaders(),
  });
};

API.fetchProjectsTypes = function () {
  return API.fetchAPIAndHandle(`/staticData/project/types`, {
    method: 'GET',
    headers: API.getAuthJSONHeaders(),
  }).then(({ data }) => API_PARSER.parseProjectType(data));
};

// ++ Insights ++
API.fetchProjectInsights = function ({ queryKey }) {
  const [, { projectId, isDemo }] = queryKey;
  const url = isDemo ? '/project/demos' : '/project';
  return API.fetchAPIAndHandle(`${url}/insights/${projectId}`, {
    method: 'GET',
    headers: API.getAuthJSONHeaders(),
  });
};

API.fetchProjectInsightsFile = function ({ file, projectId }) {
  return API.fetchAPIAndHandle(`/project/insight/file`, {
    method: 'POST',
    headers: API.getAuthJSONHeaders(),
    body: JSON.stringify({
      insight_path: file,
      project_id: projectId,
    }),
  });
};

API.fetchProjectInsightsActions = function ({ queryKey }) {
  const [, { projectId, insightId, isDemo }] = queryKey;
  const url = isDemo ? '/projects/demos' : '/projects';
  return API.fetchAPIAndHandle(`${url}/${projectId}/${insightId}/actions/`, {
    method: 'GET',
    headers: API.getAuthJSONHeaders(),
  });
};

API.createProjectInsightsAction = function (body = {}) {
  return API.fetchAPIAndHandle(`/actions/`, {
    method: 'POST',
    headers: API.getAuthJSONHeaders(),
    body: JSON.stringify(body),
  });
};

API.updateProjectInsightsAction = function ({ actionId, status }) {
  return API.fetchAPIAndHandle(`/projects/actions/${actionId}`, {
    method: 'PATCH',
    headers: API.getAuthJSONHeaders(),
    body: JSON.stringify({ status: status }),
  });
};

API.deleteProjectInsightsAction = function ({ actionId }) {
  return API.fetchAPIAndHandle(`/projects/actions/${actionId}`, {
    method: 'DELETE',
    headers: API.getAuthJSONHeaders(),
  });
};

API.fetchActionWhereOptions = function () {
  return API.fetchAPIAndHandle(`/options/actionswhere`, {
    method: 'GET',
    headers: API.getAuthJSONHeaders(),
  }).then(API_PARSER.parseWhereOptions);
};

API.fetchActionWhatOptions = function () {
  return API.fetchAPIAndHandle(`/options/actionswhat`, {
    method: 'GET',
    headers: API.getAuthJSONHeaders(),
  }).then(API_PARSER.parseWhatOptions);
};

API.fetchProjectAnalytics = function ({ queryKey }) {
  const [, { projectId, isDemo }] = queryKey;
  const url = isDemo ? '/analytics/demos' : '/analytics';
  return API.fetchAPIAndHandle(`${url}?project_id=${projectId}`, {
    method: 'GET',
    headers: API.getAuthJSONHeaders(),
  });
};

// ++ Plans ++
API.fetchPlans = function () {
  return API.fetchAPIAndHandle(`/staticData/plans`, {
    method: 'GET',
    headers: API.getAuthJSONHeaders(),
  });
};

// ++ Users ++
API.fetchUserDetail = function () {
  return API.fetchAPIAndHandle(`/user/info`, {
    method: 'GET',
    headers: API.getAuthJSONHeaders(),
  });
};

API.fetchUserSubscription = function () {
  return API.fetchAPIAndHandle(`/user/subscription/`, {
    method: 'GET',
    headers: API.getAuthJSONHeaders(),
  });
};

API.fetchUserNotifications = function () {
  return API.fetchAPIAndHandle(`/user/notifications`, {
    method: 'GET',
    headers: API.getAuthJSONHeaders(),
  });
};

API.viewAllUserNotifications = function () {
  return API.fetchAPIAndHandle(`/user/notifications/viewall`, {
    method: 'POST',
    headers: API.getAuthJSONHeaders(),
  });
};

API.fetchJobOptions = function () {
  return API.fetchAPIAndHandle(`/staticData/options/jobs`, {
    method: 'GET',
    headers: API.getAuthJSONHeaders(),
  });
};

API.fetchToolOptions = function () {
  return API.fetchAPIAndHandle(`/staticData/options/tools`, {
    method: 'GET',
    headers: API.getAuthJSONHeaders(),
  });
};

API.fetchIndustryOptions = function () {
  return API.fetchAPIAndHandle(`/staticData/options/industries`, {
    method: 'GET',
    headers: API.getAuthJSONHeaders(),
  });
};

API.fetchUseOptions = function () {
  return API.fetchAPIAndHandle(`/staticData/options/preadyUses`, {
    method: 'GET',
    headers: API.getAuthJSONHeaders(),
  });
};

export default API;
