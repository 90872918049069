import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Space } from 'antd';
import { useAPI } from 'modules/core/hooks';
import { NotificationWidget, UserInfoWidget } from 'modules/core/components';

import './AppHeader.scss';

const AppHeader = () => {
  const api = useAPI();
  const { data: userDetail, isLoading: isLoadingDetail } = useQuery({
    queryKey: ['User::fetchUserDetail'],
    queryFn: api.fetchUserDetail,
  });

  const { data: userSubscription, isLoading: isLoadingSub } = useQuery({
    queryKey: ['User::fetchUserSubscription'],
    queryFn: api.fetchUserSubscription,
  });

  const { data: notifications, isLoading: isLoadingNotifs } = useQuery({
    queryKey: ['User::fetchNotifications'],
    queryFn: api.fetchUserNotifications,
    refetchInterval: 180000, // refetch interval 3 Min
  });

  return (
    <header className="app-header">
      <div>
        <Space size={32}>
          <NotificationWidget
            loading={isLoadingNotifs}
            notifications={notifications}
          />
          <UserInfoWidget
            loading={isLoadingDetail || isLoadingSub}
            detail={userDetail}
            sub={userSubscription}
          />
        </Space>
      </div>
    </header>
  );
};

export default AppHeader;
