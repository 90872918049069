export const PROJECT_KEYMAP = {
  model_type: 'project_type_id',
};

export const PROJECT_TYPE_KEYMAP = {
  id: 'project_id',
  description: 'project_desc',
  name: 'project_name',
};

export const ACTION_WHERE_KEYMAP = {
  WhereID: 'id',
  WhereDesc: 'description',
};

export const ACTION_WHAT_KEYMAP = {
  WhatID: 'id',
  WhatDesc: 'description',
};
