import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { HelpModal } from 'modules/core/components';
import { HELPS } from 'modules/core/constants';
import { useAPI } from 'modules/core/hooks';
import { Spin } from 'antd';

const DashboardResults = ({ isDemo = false }) => {
  const api = useAPI();
  let { projectId } = useParams();
  const { data: url, isLoading } = useQuery({
    queryKey: ['Project::fetchProjectAnalytics', { projectId, isDemo }],
    queryFn: api.fetchProjectAnalytics,
    select: (d) => {
      try {
        return new URL(d?.url).href;
      } catch {
        return null;
      }
    },
    refetchOnWindowFocus: false,
  });

  if (isLoading) return <Spin />;

  return (
    <>
      <div style={{ height: '65vh', border: 0 }}>
        {url && (
          <iframe
            title="Project dashboard"
            src={url}
            height="100%"
            width="100%"
          ></iframe>
        )}
        {!url && <p>Las analíticas no se encuentran disponibles.</p>}
      </div>
      <HelpModal screen={HELPS.SCREENS.RESULTS} />
    </>
  );
};

export default DashboardResults;
