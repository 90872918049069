import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Row, Col, Tabs } from 'antd';
import {
  UserOutlined,
  UsergroupAddOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import UserProfile from './UserProfile/UserProfile';
import UserTeam from './UserTeam/UserTeam';
import UserPlan from './UserPlan/UserPlan';
import { PATHS } from 'modules/core/constants';

// TODO: Elevate fetchUserDetail here if worth value
const Configurations = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const renderTabLabel = ({ icon: Icon, text = '' }) => {
    return (
      <div>
        <Icon />
        {text}
      </div>
    );
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Tabs
          activeKey={location.pathname}
          onTabClick={navigate}
          items={[
            {
              key: PATHS.CONFIGURATION_PROFILE,
              label: renderTabLabel({ icon: UserOutlined, text: 'Perfil' }),
              children: <UserProfile />,
            },
            {
              key: PATHS.CONFIGURATION_PLAN,
              label: renderTabLabel({ icon: WalletOutlined, text: 'Plan' }),
              children: <UserPlan />,
            },
            {
              key: PATHS.CONFIGURATION_TEAM,
              label: renderTabLabel({
                icon: UsergroupAddOutlined,
                text: 'Equipo',
              }),
              children: <UserTeam />,
            },
          ]}
        />
      </Col>
    </Row>
  );
};

export default Configurations;
