import React, { useState } from 'react';
import { useAPI } from 'modules/core/hooks';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, Link } from 'react-router-dom';
import { Row, Col, Form, Input, message, Space } from 'antd';
import { PATHS } from 'modules/core/constants';
import { ButtonSecondary } from 'modules/core/components';
import { LoginTitle } from '../components';
import logoWhite from 'assets/logo-pready-white.svg';

import './PasswordRecovery.scss';

// FIXME: Review when backend is ready and add change password step
const PasswordRecovery = () => {
  const api = useAPI();
  const navigate = useNavigate();
  const [recoveryEmail, setRecoveryEmail] = useState(null);

  const { data: resetInfo } = useQuery({
    queryKey: ['Login::ResetPasswordInfo'],
    queryFn: api.fetchResetPasswordInfo,
    // FIXME: Remove [0] when carousel is implemented
    select: (infos) => infos[0],
  });

  const { mutate: passwordRecovery, isLoading: isLoadingPasswordRecovery } =
    useMutation({
      mutationFn: api.passwordRecovery,
    });

  const handleOnError = ({ message: error }) => {
    console.log('ERROR', error);
    message.error('Error al conectarse al servidor');
  };

  const onFinish = (values) => {
    passwordRecovery(
      { ...values },
      {
        onSuccess: () => setRecoveryEmail(values.email),
        onError: handleOnError,
      }
    );
  };

  const renderRecoveryForm = () => (
    <Form
      name="basic"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, message: 'El campo es requerido' },
          { type: 'email', message: 'El campo debe ser un email' },
        ]}
      >
        <Input size="large" placeholder="Ingrese su correo electronico" />
      </Form.Item>
      <Form.Item>
        <ButtonSecondary
          block
          type="primary"
          size="large"
          htmlType="submit"
          loading={isLoadingPasswordRecovery}
        >
          Continuar
        </ButtonSecondary>
      </Form.Item>
    </Form>
  );

  const renderRecoveryFormStep = () => (
    <Space direction="vertical" size="large">
      <LoginTitle
        title="¿Olvidaste tu contraseña?"
        subtitle="Ingresa tu correo electronico y te enviaremos instrucciones para restrablecer la contraseña."
      />
      {renderRecoveryForm()}
      <span className="pready-login-form-footer">
        <Link to={PATHS.LOGIN} className="pready-login-link">
          Volver
        </Link>
      </span>
    </Space>
  );

  const renderCheckEmailStep = () => (
    <Space direction="vertical" size="large">
      <LoginTitle
        title="Revisa tu correo electronico"
        subtitle={`Se enviaron instrucciones para restablecer la contraseña a: ${recoveryEmail}`}
      />
      <ButtonSecondary
        block
        type="primary"
        size="large"
        onClick={() => navigate(PATHS.LOGIN)}
      >
        Continuar
      </ButtonSecondary>
    </Space>
  );

  return (
    <Row className="pready-password-recovery">
      <Col xs={0} lg={12} className="password-recovery-sider">
        <img
          className="password-recovery-logo"
          src={logoWhite}
          alt="Logo Pready"
        />
        <div className="recovery-info-content">{resetInfo?.info}</div>
      </Col>
      <Col xs={24} lg={12} className="password-recovery-form-container">
        {!recoveryEmail && renderRecoveryFormStep()}
        {recoveryEmail && renderCheckEmailStep()}
      </Col>
    </Row>
  );
};

export default PasswordRecovery;
