import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { Button, Col, Row, Space, Spin, Tabs, Typography } from 'antd';
import {
  CloudArrowDownIcon,
  CloudArrowUpIcon,
} from '@heroicons/react/20/solid';
import { useAPI } from 'modules/core/hooks';
import { SimpleModal, Table, UploadButton } from 'modules/core/components';
import './DataExamplesModal.scss';

const FileExample = ({ example, renderUpload: UploadFile }) => (
  <Row gutter={16}>
    <Col span={12}>
      <Table
        scroll={{ y: '55vh' }}
        rowKey="id"
        dataSource={example.fields}
        columns={[
          { dataIndex: 'name', title: 'Columna', width: '33%' },
          { dataIndex: 'field_type', title: 'Tipo', width: '33%' },
          { dataIndex: 'example', title: 'Muestra', width: '33%' },
        ]}
      />
    </Col>
    <Col span={10} offset={1}>
      <Space direction="vertical" align="center">
        <Typography.Text className="tab-item-text">
          Armamos unos ejemplos para vos, de las columnas que se necesitan si el
          proyecto es general, educación, salud o punto de venta.
        </Typography.Text>
        {example.url && (
          <Link to={example.url}>
            <Button size="large" className="tab-item-button" type="primary">
              <CloudArrowDownIcon width={20} />
              Descargar ejemplo
            </Button>
          </Link>
        )}
        <UploadFile />
      </Space>
    </Col>
  </Row>
);

const FileExamples = ({ examples, ...props }) => (
  <Tabs
    defaultActiveKey={examples[0].id}
    items={examples.map((i) => ({
      key: i.id,
      label: i.file_type,
      children: <FileExample key={i.id} example={i} {...props} />,
    }))}
  />
);

const UploadFileButton = ({ uploadFn, onUpload }) => (
  <UploadButton
    apiFn={uploadFn}
    onUpload={onUpload}
    size="large"
    className="tab-item-button"
    type="primary"
    maxSize={100}
    fileTypes={[
      { type: 'text/csv', label: 'CSV' },
      {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        label: 'XLSX',
      },
    ]}
  >
    <CloudArrowUpIcon width={20} />
    Subir datos
  </UploadButton>
);

const DataExamplesModal = ({ open, onCancel, onUpload }) => {
  const api = useAPI();
  const { data: examples, isLoading: loadingExamples } = useQuery({
    queryKey: ['Files::Examples'],
    queryFn: api.fetchFilesExamples,
    select: ({ data: e }) => e,
    enabled: open,
  });

  return (
    <SimpleModal
      open={open}
      onCancel={onCancel}
      footer={null}
      bodyStyle={{ height: '70vh' }}
      width={'80%'}
    >
      <Row gutter={16}>
        <Col span={24}>
          {loadingExamples && <Spin />}
          {examples && (
            <FileExamples
              examples={examples}
              renderUpload={() => (
                <UploadFileButton
                  uploadFn={api.uploadFile}
                  onUpload={onUpload}
                />
              )}
            />
          )}
        </Col>
      </Row>
    </SimpleModal>
  );
};

export default DataExamplesModal;
