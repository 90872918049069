import React from 'react';
import ProjectCardContent from './components/ProjectCardContent';
import ProjectCardCreate from './components/ProjectCardCreate';
import BADGES from './components/badges';

const ProjectCard = ({ newProject = false, ...props }) => {
  return newProject ? (
    <ProjectCardCreate {...props} />
  ) : (
    <ProjectCardContent {...props} />
  );
};

export default ProjectCard;
export { BADGES };
