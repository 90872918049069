import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAPI } from 'modules/core/hooks';
import { useQuery } from '@tanstack/react-query';
import { RightOutlined } from '@ant-design/icons';
import { Row, Col, Space, Typography } from 'antd';
import { PATHS } from 'modules/core/constants';
import {
  ProjectFilters,
  ProjectGrid,
  TitleExtra,
} from 'modules/core/components';

const { Text } = Typography;

const getFilteredProjects = (projects, types, filteredObjectiveId) => {
  if (Array.isArray(projects)) {
    const filteredTypes = types?.filter(
      (t) => t.objective_id === filteredObjectiveId
    );
    return filteredTypes
      ? projects.filter((p) =>
          filteredTypes?.some((t) => t.project_id === p.project_type_id)
        )
      : projects;
  } else return [];
};

// TODO: Enable objectives subtitle when project count is available
const ProjectDemos = () => {
  const navigate = useNavigate();
  const [filterSelected, setFilterSelected] = useState();
  const selectFilter = (f) => setFilterSelected((p) => f ?? p);

  const api = useAPI();
  const { data: demos, isLoading: loadingDemos } = useQuery({
    queryKey: ['Projects::fetchProjectsDemos', { isDemo: true }],
    queryFn: api.fetchProjects,
  });

  const { data: projectsObjectives, isLoading: loadingObjectives } = useQuery({
    queryKey: ['Projects::fetchProjectsObjectives'],
    queryFn: api.fetchProjectsObjectives,
    // select: (data) =>
    // data.map((el) => ({ ...el, subtitle: `${el?.projects} proyectos` })),
  });
  useEffect(() => {
    if (projectsObjectives) setFilterSelected(projectsObjectives[0]);
  }, [projectsObjectives]);

  const { data: types, isLoading: isLoadingTypes } = useQuery({
    queryKey: ['Projects::fetchProjectsTypes'],
    queryFn: api.fetchProjectsTypes,
    enabled: !!projectsObjectives,
  });

  const renderHeaderNavigation = () => (
    <Col span={24}>
      <Text type="secondary">
        <Space>
          <RightOutlined />
          Inicio
          <RightOutlined />
          Demostraciones
        </Space>
      </Text>
    </Col>
  );

  return (
    <Row gutter={[16, 16]}>
      {renderHeaderNavigation()}
      <Col span={24}>
        <TitleExtra title="Demostraciones" />
      </Col>
      <Col span={24}>
        <ProjectFilters
          data={projectsObjectives}
          loading={loadingObjectives || isLoadingTypes}
          selected={filterSelected}
          onSelect={selectFilter}
        />
      </Col>
      <Col span={24}>
        <ProjectGrid
          data={getFilteredProjects(demos, types, filterSelected?.id)}
          loading={loadingDemos}
          onClick={(p) =>
            navigate(`${PATHS.OBJECTIVES_PROJECTS_DEMOS}/${p.project_id}`)
          }
        />
      </Col>
    </Row>
  );
};

export default ProjectDemos;
