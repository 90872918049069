import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAPI } from 'modules/core/hooks';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Row, Col } from 'antd';
import { PATHS } from 'modules/core/constants';
import {
  ProjectFilters,
  ProjectGrid,
  ProjectNameModal,
  SimpleModal,
  TitleExtra,
} from 'modules/core/components';

const getFilteredProjects = (projects, types, filteredObjectiveId) => {
  if (Array.isArray(projects)) {
    const filteredTypes = types?.filter(
      (t) => t.objective_id === filteredObjectiveId
    );
    return filteredTypes
      ? projects.filter((p) =>
          filteredTypes?.some((t) => t.project_id === p.project_type_id)
        )
      : projects;
  } else return [];
};

// TODO: Add edit projects
// TODO: Enable objectives subtitle when project count is available
const ProjectList = () => {
  const navigate = useNavigate();
  const [editSelected, setEditSelected] = useState(null);
  const [filterSelected, setFilterSelected] = useState();
  const selectFilter = (f) => setFilterSelected((p) => f ?? p);

  const api = useAPI();
  const {
    data: projects,
    isLoading: loadingProjects,
    refetch,
  } = useQuery({ queryKey: ['Projects::Fetch'], queryFn: api.fetchProjects });

  const { data: projectsObjectives, isLoading: loadingObjectives } = useQuery({
    queryKey: ['Projects::fetchProjectsObjectives'],
    queryFn: api.fetchProjectsObjectives,
    // select: (data) =>
    // data.map((el) => ({ ...el, subtitle: `${el?.projects} proyectos` })),
  });
  useEffect(() => {
    if (projectsObjectives) setFilterSelected(projectsObjectives[0]);
  }, [projectsObjectives]);

  const { data: types, isLoading: isLoadingTypes } = useQuery({
    queryKey: ['Projects::fetchProjectsTypes'],
    queryFn: api.fetchProjectsTypes,
    enabled: !!projectsObjectives,
  });

  const [deleteProjectModal, setDeleteProjectModal] = useState({
    open: false,
    item: null,
  });
  const onCancelDeleteProject = () =>
    setDeleteProjectModal({ open: false, item: null });

  const { mutate: deleteProject, isLoading: deletingProject } = useMutation({
    mutationFn: api.deleteProject,
  });
  const onDeleteProject = ({ onSuccess, onError }) => {
    deleteProject(
      { projectId: deleteProjectModal?.item?.project_id },
      { onSuccess, onError }
    );
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <TitleExtra title="Tus proyectos" />
      </Col>
      <Col span={24}>
        <ProjectFilters
          data={projectsObjectives}
          loading={loadingObjectives || isLoadingTypes}
          selected={filterSelected}
          onSelect={selectFilter}
        />
      </Col>
      <Col span={24}>
        <ProjectGrid
          data={getFilteredProjects(projects, types, filterSelected?.id)}
          loading={loadingProjects}
          onNew={() => navigate(PATHS.OBJECTIVES_PROJECTS)}
          onEdit={setEditSelected}
          onDelete={(p) => setDeleteProjectModal({ open: true, item: p })}
          onClick={(p) =>
            navigate(`${PATHS.OBJECTIVES_PROJECTS}/${p.project_id}`)
          }
        />
        <ProjectNameModal
          isEditing
          open={!!editSelected}
          initialValues={editSelected}
          onSuccess={() => {
            refetch();
            setEditSelected(null);
          }}
          onCancel={() => setEditSelected(null)}
        />
        <SimpleModal
          title="Eliminar proyecto"
          open={deleteProjectModal.open}
          okText="Eliminar"
          onOk={onDeleteProject}
          confirmLoading={deletingProject}
          onSuccessText="Se eliminó el proyecto correctamente"
          onSuccess={() => {
            onCancelDeleteProject();
            refetch();
          }}
          onCancel={onCancelDeleteProject}
        >
          ¿Deseas elimnar el proyecto?
        </SimpleModal>
      </Col>
    </Row>
  );
};

export default ProjectList;
