import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAPI } from 'modules/core/hooks';
import { PATHS } from 'modules/core/constants';

const RequireAuth = ({ children }) => {
  const api = useAPI();
  if (api.authToken) return children;
  return <Navigate to={PATHS.LOGIN} replace />;
};

export default RequireAuth;
