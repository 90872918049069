import React from 'react';
import { useAPI } from 'modules/core/hooks';
import { useQuery } from '@tanstack/react-query';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Row, Col, Spin, Button, Space } from 'antd';
import { Table, TitleExtra, UserPlanPill } from 'modules/core/components';

const MOCK_TEAM = [];

// TODO: Improve styles
// TODO: Finish Add, Delete and Edit user actions
// TODO: Re-enable add user when implemented
const UserTeam = () => {
  const api = useAPI();
  const { data: subscription, isLoading: isLoadingSub } = useQuery({
    queryKey: ['User::fetchUserSubscription'],
    queryFn: api.fetchUserSubscription,
    select: (sub) => ({
      name: sub.subscription_name,
      usersTotal: sub.subscription_limits.users.total,
      usersLeft:
        sub.subscription_limits.users.total -
        sub.subscription_limits.users.used,
    }),
  });

  const columns = [
    { dataIndex: 'fullname', title: 'Nombre' },
    { dataIndex: 'email', title: 'Correo' },
    { dataIndex: 'rol', title: 'Rol en el equipo' },
    {
      dataIndex: 'id',
      title: '',
      width: '5%',
      align: 'right',
      render: (_, record) => (
        <Space size="large">
          <Button
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={() => console.log('Eliminar', record.id)}
          />
          <Button
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => console.log('Editar', record.id)}
          />
        </Space>
      ),
    },
  ];

  if (isLoadingSub) return <Spin />;

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <TitleExtra title="Equipo" />
      </Col>
      <Col span={24}>
        <UserPlanPill
          tier={subscription?.name}
          numberOfUsers={subscription?.usersTotal}
        />
      </Col>
      <Col span={24}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '25px 0',
          }}
        >
          <Button
            type="primary"
            size="large"
            style={{ minWidth: 200 }}
            icon={<PlusCircleOutlined />}
            disabled={true}
          >
            Agregar usuario
          </Button>
          <div style={{ textAlign: 'end' }}>
            Usuarios disponibles: {subscription?.usersLeft}
          </div>
        </div>
      </Col>
      <Col span={24}>
        <Table rowKey="id" dataSource={MOCK_TEAM} columns={columns} />
      </Col>
    </Row>
  );
};

export default UserTeam;
