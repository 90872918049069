import React from 'react';
import { NavLink } from 'react-router-dom';
import { Layout, Space } from 'antd';
import { PATHS } from 'modules/core/constants';
import logoBlack from 'assets/logo-pready-black.svg';
import './MessageScreen.scss';

const MessageScreen = ({ title, body, showReturnHome = false }) => {
  return (
    <Layout className="message-screen-layout">
      <Layout.Content className="message-screen-content">
        <Space direction="vertical" size="middle">
          <img
            src={logoBlack}
            alt="Logo Pready"
            className="message-screen-logo"
          />
          <div>
            <h1>{title}</h1>
            <p>{body}</p>
            {showReturnHome && (
              <NavLink to={PATHS.HOME}>Volver al inicio</NavLink>
            )}
          </div>
        </Space>
      </Layout.Content>
    </Layout>
  );
};

export default MessageScreen;
