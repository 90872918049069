import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Row, Col, List, Calendar, TimePicker } from 'antd';
import { useAPI } from 'modules/core/hooks';
import { DATETIME } from 'modules/core/utils';
import { SimpleModal, TitleExtra } from 'modules/core/components';
import './CreateAccionable.scss';

const CreateAccionable = ({ open = false, onCreate, onCancel, loading }) => {
  const [what, setWhat] = useState();
  const [where, setWhere] = useState();
  const [when, setWhen] = useState();
  const resetModal = useCallback(() => {
    setWhat();
    setWhere();
    setWhen(dayjs().add(1, 'w'));
  }, []);
  useEffect(() => {
    !open && resetModal();
  }, [open, resetModal]);

  const api = useAPI();
  const { data: optionsWhere, isLoading: isLoadingOptionsWhere } = useQuery({
    queryKey: ['Projects::Insights::fetchActionWhereOptions'],
    queryFn: api.fetchActionWhereOptions,
    enabled: open,
  });
  const { data: optionsWhat, isLoading: isLoadingOptionsWhat } = useQuery({
    queryKey: ['Projects::Insights::fetchActionWhatOptions'],
    queryFn: api.fetchActionWhatOptions,
    enabled: open,
  });
  const loadingOptions = isLoadingOptionsWhere || isLoadingOptionsWhat;
  const disableCreate = !(what && where && when);

  return (
    <SimpleModal
      open={open}
      onOk={() => onCreate({ what, where, when })}
      onCancel={onCancel}
      okText="Crear"
      className={'create-accionable-modal'}
      width="85%"
      confirmLoading={loading}
      cancelButtonProps={{ className: 'create-accionable-modal-cancel-btn' }}
      okButtonProps={{
        className: 'create-accionable-modal-ok-btn',
        disabled: disableCreate,
      }}
    >
      <Row gutter={[32, 16]} className="create-accionable">
        <Col span={8} className="create-accionable-column with-divider">
          <TitleExtra title={'Qué'} />
          <AccionableOptionList
            selected={what}
            onSelect={setWhat}
            options={optionsWhat}
            loading={loadingOptions}
          />
        </Col>
        <Col span={8} className="create-accionable-column with-divider">
          <TitleExtra title={'Dónde'} />
          <AccionableOptionList
            selected={where}
            onSelect={setWhere}
            options={optionsWhere}
            loading={loadingOptions}
          />
        </Col>
        <Col span={8} className="create-accionable-column">
          <TitleExtra title={'Cuándo'} />
          <AccionableDate selected={when} onSelect={setWhen} />
        </Col>
        <Col span={24}>
          <AccionableText
            what={what?.description}
            where={where?.description}
            when={when && DATETIME.formatDateTime(when)}
          />
        </Col>
      </Row>
    </SimpleModal>
  );
};

const AccionableOptionList = ({ selected, onSelect, options, loading }) => {
  return (
    <List
      className="accionable-option-list"
      grid={{ gutter: [16, 0], column: 1 }}
      dataSource={options}
      loading={loading}
      renderItem={(o) => (
        <List.Item>
          <AccionableOption
            title={o.description}
            selected={o.id === selected?.id}
            onClick={() => onSelect(o)}
          />
        </List.Item>
      )}
    />
  );
};
const AccionableOption = ({ selected = false, title, onClick }) => (
  <div
    className={
      selected
        ? 'accionable-option accionable-option-selected'
        : 'accionable-option'
    }
    onClick={onClick}
  >
    {title}
  </div>
);

const AccionableDate = ({ selected, onSelect }) => {
  return (
    <div className="accionable-date">
      <Calendar
        validRange={[dayjs().subtract(1, 'd'), dayjs().add(3, 'y')]}
        value={selected}
        fullscreen={false}
        onChange={onSelect}
      />
      <TimePicker
        className="accionable-date-time"
        onSelect={onSelect}
        value={selected}
        format="hh:mm A"
        size="large"
        allowClear={false}
        showNow={false}
        inputReadOnly
        suffixIcon={null}
      />
    </div>
  );
};

const AccionableText = ({
  what = '[Qué]',
  where = '[Dónde]',
  when = '[Cuándo]',
}) => (
  <div className="create-accionable-text">
    Ofrecer <span className="create-accionable-text-highlight">{what}</span> por{' '}
    <span className="create-accionable-text-highlight">{where}</span> para el{' '}
    <span className="create-accionable-text-highlight">{when}</span>.
  </div>
);

export default CreateAccionable;
