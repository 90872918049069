import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useAPI } from 'modules/core/hooks';
import { DATETIME } from 'modules/core/utils';
import { SimpleModal, Table } from 'modules/core/components';

const { Text } = Typography;

const FileHistory = ({ data, loading, refetch, ...props }) => {
  const api = useAPI();
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    item: null,
  });

  const { mutate: deleteFile, isLoading: deletingFile } = useMutation({
    mutationFn: api.deleteFile,
  });

  const onCancelDeleteFile = () => setDeleteModal({ open: false, item: null });

  const onDeleteFile = ({ onSuccess, onError }) => {
    deleteFile({ fileName: deleteModal?.item?.name }, { onSuccess, onError });
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Fecha de carga',
      dataIndex: 'date',
      key: 'date',
      render: (d) => DATETIME.formatDate(d),
    },
    {
      align: 'center',
      render: (f) => (
        <Button
          type="text"
          shape="circle"
          icon={<DeleteOutlined />}
          onClick={() => setDeleteModal({ open: true, item: f })}
        />
      ),
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={data}
        loading={loading || deletingFile}
        {...props}
      />
      <SimpleModal
        title="Eliminar archivo"
        open={deleteModal.open}
        okText="Eliminar"
        onOk={onDeleteFile}
        confirmLoading={deletingFile}
        onSuccessText="Se eliminó el archivo correctamente"
        onSuccess={() => {
          onCancelDeleteFile();
          refetch();
        }}
        onCancel={onCancelDeleteFile}
      >
        <Text>¿Deseas elimnar el archivo?</Text>
      </SimpleModal>
    </>
  );
};

export default FileHistory;
