import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import theme from 'styles/theme';
import { MessageScreen } from 'modules/core/components';
import { PATHS } from 'modules/core/constants';
import { AppLayout } from 'modules/core/layouts';
import { LOCAL_STORAGE } from 'modules/core/utils';
import { APIProvider, PreadyAPI } from 'modules/api';
import { Home } from 'modules/home';
import {
  NewProject,
  ProjectDemos,
  ProjectList,
  ProjectResults,
  ProjectStatusValidator,
} from 'modules/projects';
import { Configurations } from 'modules/configurations';
import { Notifications } from 'modules/notifications';
import { Login, Logout, PasswordRecovery, Register } from 'modules/login';
import { RequireAuth } from './components';

// Locale
import esES from 'antd/locale/es_ES';
import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';
dayjs.locale('es-mx');

const queryClient = new QueryClient();

const App = () => {
  // NOTE: Maybe this could be a component in objectives folder
  const renderObjectivesRoutes = () => (
    <Route path={PATHS.OBJECTIVES}>
      <Route index element={<ProjectList />} />
      <Route path={PATHS.OBJECTIVES_PROJECTS} element={<NewProject />} />
      <Route
        path={`${PATHS.OBJECTIVES_PROJECTS_DEMOS}/:projectId`}
        element={<ProjectResults isDemo={true} />}
      />
      <Route
        path={`${PATHS.OBJECTIVES_PROJECTS}/:projectId`}
        element={<ProjectStatusValidator />}
      />
    </Route>
  );

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <APIProvider api={PreadyAPI.withAuthUser(LOCAL_STORAGE.TOKEN.get())}>
          <ConfigProvider locale={esES} theme={theme.pready}>
            <Routes>
              <Route path={PATHS.LOGIN} element={<Login />} />
              <Route path={PATHS.LOGOUT} element={<Logout />} />
              <Route path={PATHS.REGISTER} element={<Register />} />
              <Route
                path={PATHS.PASSWORD_RECOVERY}
                element={<PasswordRecovery />}
              />
              <Route
                path={PATHS.HOME}
                element={
                  <RequireAuth>
                    <AppLayout />
                  </RequireAuth>
                }
              >
                <Route index element={<Home />} />
                <Route path={PATHS.DEMOS} element={<ProjectDemos />} />
                {renderObjectivesRoutes()}
                <Route
                  path={PATHS.CONFIGURATION_PROFILE}
                  element={<Configurations />}
                />
                <Route
                  path={PATHS.CONFIGURATION_PLAN}
                  element={<Configurations />}
                />
                <Route
                  path={PATHS.CONFIGURATION_TEAM}
                  element={<Configurations />}
                />
                <Route path={PATHS.NOTIFICATIONS} element={<Notifications />} />
              </Route>
              <Route
                path="*"
                element={
                  <MessageScreen
                    title="Oops!"
                    body="Se ha producido un error inesperado"
                    showReturnHome
                  />
                }
              />
            </Routes>
          </ConfigProvider>
        </APIProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
