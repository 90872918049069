import React from 'react';
import { List } from 'antd';

import './CardOptionsList.scss';

const isSelected = (selected, option) => {
  if (Array.isArray(selected)) {
    return selected.some(({ id }) => id === option.id);
  } else return selected?.id === option.id;
};

// NOTE: A futuro podría estar en las listas de creación de insights.
const CardOptionsList = ({
  dataSource = [],
  selected,
  onSelect,
  loading,
  column = 2,
  ...props
}) => {
  return (
    <List
      dataSource={dataSource}
      className="card-options-list"
      grid={{ gutter: [16, 0], column }}
      locale={{ emptyText: 'No hay opciones disponible' }}
      loading={loading}
      renderItem={(o) => (
        <List.Item>
          <OptionItem
            title={o.name}
            selected={isSelected(selected, o)}
            onClick={() => onSelect(o)}
          />
        </List.Item>
      )}
      {...props}
    />
  );
};

const OptionItem = ({ selected = false, title, onClick }) => (
  <div
    className={
      selected
        ? 'card-options-item card-options-item-selected'
        : 'card-options-item'
    }
    onClick={onClick}
  >
    {title}
  </div>
);

export default CardOptionsList;
