import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAPI } from 'modules/core/hooks';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Row, Col, Typography } from 'antd';
import {
  ButtonSecondary,
  FileHistory,
  HelpModal,
  ProjectGrid,
  SimpleModal,
  TitleExtra,
} from 'modules/core/components';
import { HELPS, PATHS } from 'modules/core/constants';

const { Text } = Typography;

const Home = () => {
  const api = useAPI();
  const navigate = useNavigate();
  const {
    data: projects,
    isLoading: loadingProjects,
    refetch,
  } = useQuery({
    queryKey: ['Projects::Fetch'],
    queryFn: api.fetchProjects,
    select: (projects) => projects?.slice(0, 5),
  });
  const { data: demos, isLoading: loadingDemos } = useQuery({
    queryKey: ['Projects::FetchDemos', { isDemo: true }],
    queryFn: api.fetchProjects,
    select: (projects) => projects?.slice(0, 5),
  });
  const {
    data: files,
    isLoading: loadingFiles,
    refetch: refetchHistory,
  } = useQuery({
    queryKey: ['Files::History'],
    queryFn: api.fetchFilesHistory,
  });

  const [deleteProjectModal, setDeleteProjectModal] = useState({
    open: false,
    item: null,
  });
  const onCancelDeleteProject = () =>
    setDeleteProjectModal({ open: false, item: null });

  const { mutate: deleteProject, isLoading: deletingProject } = useMutation({
    mutationFn: api.deleteProject,
  });
  const onDeleteProject = ({ onSuccess, onError }) => {
    deleteProject(
      { projectId: deleteProjectModal?.item?.project_id },
      { onSuccess, onError }
    );
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <TitleExtra
          title="Demostraciones"
          level={4}
          extra={
            <ButtonSecondary
              type="primary"
              onClick={(p) => navigate(`${PATHS.DEMOS}`)}
            >
              Ver más
            </ButtonSecondary>
          }
        />
      </Col>
      <Col span={24}>
        <ProjectGrid
          data={demos}
          loading={loadingDemos}
          onClick={(p) =>
            navigate(`${PATHS.OBJECTIVES_PROJECTS_DEMOS}/${p.project_id}`)
          }
        />
      </Col>
      <Col span={24}>
        <TitleExtra
          title="Tus proyectos"
          level={4}
          extra={
            <ButtonSecondary
              type="primary"
              onClick={() => navigate(`${PATHS.OBJECTIVES}`)}
            >
              Ver más
            </ButtonSecondary>
          }
        />
      </Col>
      <Col span={24}>
        <ProjectGrid
          data={projects}
          loading={loadingProjects}
          onNew={() => navigate(PATHS.OBJECTIVES_PROJECTS)}
          onEdit={() => {}}
          onDelete={(p) => setDeleteProjectModal({ open: true, item: p })}
          onClick={(p) =>
            navigate(`${PATHS.OBJECTIVES_PROJECTS}/${p.project_id}`)
          }
        />
        <SimpleModal
          title="Eliminar proyecto"
          open={deleteProjectModal.open}
          okText="Eliminar"
          onOk={onDeleteProject}
          confirmLoading={deletingProject}
          onSuccessText="Se eliminó el proyecto correctamente"
          onSuccess={() => {
            onCancelDeleteProject();
            refetch();
          }}
          onCancel={onCancelDeleteProject}
        >
          <Text>¿Deseas elimnar el proyecto?</Text>
        </SimpleModal>
      </Col>
      <Col span={12}>
        <TitleExtra title="Tu historial de carga de datos" level={4} />
        {files?.length > 0 && (
          <FileHistory
            data={files}
            loading={loadingFiles}
            refetch={refetchHistory}
          />
        )}
        {files?.length <= 0 && (
          <Text>
            Cuando empieces a crear proyectos, el historial de carga de datos va
            a aparecer aquí.
            <br />
            Te invitamos a que crees un nuevo proyecto.
          </Text>
        )}
      </Col>
      <HelpModal screen={HELPS.SCREENS.HOME} />
    </Row>
  );
};

export default Home;
