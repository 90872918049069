import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  HomeIcon,
  RocketLaunchIcon,
  TrophyIcon,
  ArrowRightOnRectangleIcon,
  Cog6ToothIcon,
} from '@heroicons/react/24/solid';
import { PATHS } from 'modules/core/constants';
import logoAws from 'assets/logo-aws.png';

import './SiderMenu.scss';

const itemsTop = [
  {
    title: 'Inicio',
    path: PATHS.HOME,
    icon: <HomeIcon width={20} />,
  },
  {
    title: 'Tus proyectos',
    path: PATHS.OBJECTIVES,
    icon: <TrophyIcon width={20} />,
  },
  {
    title: 'Elegir plan',
    path: PATHS.CONFIGURATION_PLAN,
    icon: <RocketLaunchIcon width={20} />,
  },
];

const itemsBottom = [
  {
    title: 'Configuración',
    path: PATHS.CONFIGURATION_PROFILE,
    icon: <Cog6ToothIcon width={20} />,
  },
  {
    title: 'Salir',
    path: PATHS.LOGOUT,
    icon: <ArrowRightOnRectangleIcon width={20} />,
  },
];

//FIXME: Review itemsBottom when there is scroll in the main content.
const SiderMenu = () => {
  const renderListItems = (items = []) => (
    <ul>
      {items.map((el) => (
        <li key={el.path}>
          <NavLink
            to={el.path}
            className={({ isActive }) =>
              isActive ? 'li-nav-link active-nav-link' : 'li-nav-link'
            }
          >
            <div className="li-nav-content">
              {el.icon} {el.title}
            </div>
          </NavLink>
        </li>
      ))}
    </ul>
  );

  return (
    <nav className="nav-sider-menu">
      {renderListItems(itemsTop)}
      {renderListItems(itemsBottom)}
      <img src={logoAws} alt="Logo AWS" className="aws-logo" />
    </nav>
  );
};

export default SiderMenu;
