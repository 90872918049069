import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Steps } from 'antd';
import { PATHS, PROJECTS } from 'modules/core/constants';
import {
  StepAddData,
  StepConfiguration,
  StepDiagnostic,
  StepProjectType,
} from './components';

const totalSteps = PROJECTS.STEPS_LIST.length - 1;

const NewProject = ({ initialData = {}, step = 0 }) => {
  const navigate = useNavigate();

  const [data, setData] = useState(initialData);
  const [currentStep, setCurrentStep] = useState(step);

  // TODO: Review for handle last step
  const handleNextStep = (data) => {
    setData(data);
    if (currentStep < totalSteps) {
      setCurrentStep((prevCurrentStep) => prevCurrentStep + 1);
    }
    if (currentStep === totalSteps) {
      navigate(PATHS.HOME);
    }
  };

  const handleResetStep = (step = PROJECTS.STEPS_ID.AGREGAR_DATOS) => {
    setCurrentStep(step);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Steps
          current={currentStep}
          size="small"
          labelPlacement="vertical"
          items={PROJECTS.STEPS_LIST}
        />
      </Col>
      {currentStep === PROJECTS.STEPS_ID.CREAR && (
        <StepProjectType onClickNext={handleNextStep} />
      )}
      {currentStep === PROJECTS.STEPS_ID.AGREGAR_DATOS && (
        <StepAddData data={data} onClickNext={handleNextStep} />
      )}
      {currentStep === PROJECTS.STEPS_ID.CONFIGURACION && (
        <StepConfiguration
          data={data}
          onClickNext={handleNextStep}
          onClickBack={handleResetStep}
        />
      )}
      {currentStep === PROJECTS.STEPS_ID.DIAGNOSTICO && (
        <StepDiagnostic
          data={data}
          onClickNext={handleNextStep}
          onClickBack={handleResetStep}
        />
      )}
    </Row>
  );
};

export default NewProject;
