import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Space, Typography } from 'antd';
import { useAPI } from 'modules/core/hooks';
import { CardOptionsList } from 'modules/core/components';
import { LoginTitle } from 'modules/login/components';
import SkipNextButtons from './SkipNextButtons';

const StepRole = ({ onNext, onSkip }) => {
  const api = useAPI();
  const { data: jobs, isLoading: isLoadingJobs } = useQuery({
    queryKey: ['Configuration::fetchJobs'],
    queryFn: api.fetchJobOptions,
  });
  const [selected, setSelected] = useState(null);
  return (
    <Space direction="vertical" size="large">
      <LoginTitle
        title="Queremos conocerte más"
        subtitle="Buscamos diseñar mejoras caracteristicas en base a tus intereses y conocimientos."
      />
      <Typography.Text strong>
        ¿Con qué rol te sentís identificado?
      </Typography.Text>
      <CardOptionsList
        dataSource={jobs}
        loading={isLoadingJobs}
        selected={selected}
        onSelect={setSelected}
      />
      <SkipNextButtons
        disabled={!selected}
        onNext={() => onNext({ job_id: selected?.id })}
        onSkip={onSkip}
      />
    </Space>
  );
};

export default StepRole;
